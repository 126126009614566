import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getTokenCookies } from "@/utils/cookies";

/**@desc 회원이 아닐 때만 하위 컴포넌트 렌더링하는 provider */
export default function OnlyGuestProvider() {
  const { accessToken, userInfo } = getTokenCookies();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("accessToken", accessToken);
    console.log("userInfo", userInfo);
    if (accessToken && userInfo) return navigate(-1, { replace: true });
  }, []);

  return !accessToken && !userInfo && <Outlet />;
}
