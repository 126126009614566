/**
 * 컴포넌트 다발적 사용으로 인한 참조 순환을 방지하기 위해
 * index.js에서 import해주어 참조 순환 방지
 * */

import ModalHeader from "./ModalHeader";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";

/**
 * @desc 스타일 지정하여 사용 가능한 모달 컴포넌트이며 width, height 를 반드시 지정해주어야 함<br>
 * includesClassName: 모달에 지정된 클래스에 모두 포함될 클래스 <br>
 * preventOverlayClose: 오버레이 클릭 시 모달이 닫히는 것을 막는 props <br>
 * theme: 현재는 기본과 미니 모달의 테마만 존재 (기본: "", 미니모달: mini)
 * @param {{
 * includesClassName: string,
 * theme: "mini" | "",
 * setIsOpen: any,
 * children: any,
 * modalStyle: CSSProperties,
 * isOpen: boolean,
 * closeFunction: any,
 * preventOverlayClose: boolean,
 * overlayStyle: CSSProperties,
 * isCustomScroll: boolean
 * }}
 *  */
export default function Modal({
  children,
  modalContainerStyle = {},
  modalContentWrapperStyle = {},
  overlayStyle = {},
  modalTitle,
  setIsOpen,
  isOpen,
  preventOverlayClose, // true인 경우 오버레이 클릭 시 모달 닫히는걸 막음
  closeFunction,
  contentClassName = "",
  includesClassName = "",
  isCustomScroll,
}) {
  const overlay = useRef(null);

  // ESC 키다운 이벤트
  const onKeyDown = (e) => {
    if ((closeFunction || setIsOpen) && e.key === "Escape") {
      if (closeFunction) closeFunction();
      if (setIsOpen) setIsOpen(false);
    }
  };

  // 모달 close 함수
  const onClose = () => {
    if (isOpen) {
      if (closeFunction) closeFunction();
      if (setIsOpen) setIsOpen(false);
    }
  };

  // init effect
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, { capture: false });
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={overlay}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className={`OverlaySection ${includesClassName}`}
          style={overlayStyle}
          onClick={() => {
            // 오버레이 클릭 시 클로즈
            if (!preventOverlayClose) onClose();
          }}
        >
          <div
            className={`modal_container ${includesClassName}`}
            style={modalContainerStyle}
            onClick={(e) => e.stopPropagation()}
          >
            <ModalHeader
              modalTitle={modalTitle}
              onClose={onClose}
              includesClassName={includesClassName}
            />
            <div
              className={[`modal_content_wrapper ${includesClassName}`].join(" ")}
              style={modalContentWrapperStyle}
            >
              <div
                className={[
                  `modal_content  ${includesClassName}`,
                  isCustomScroll ? "vertical_custom_scroll" : "",
                  contentClassName && contentClassName,
                ].join(" ")}
              >
                {children}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

// export default React.memo(Modal);
