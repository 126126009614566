import {formattedDate} from "@/utils/formatDate";
import {createSlice} from "@reduxjs/toolkit";

// 생년월일 및 날짜
const initialState = {
  storeYear: "",
  storeMonth: "",
  storeDay: "",
};

const dateSlice = createSlice({
  name: "dateSlice",
  initialState,
  reducers: {
    setStoreYear: (state, action) => {
      const storeYear = action.payload.storeYear;
      // console.log("storeYear", storeYear);
      state.storeYear = storeYear;
    },
    setStoreMonth: (state, action) => {
      const storeMonth = action.payload.storeMonth;
      // console.log("storeMonth", storeMonth);
      state.storeMonth = storeMonth;
    },
    setStoreDay: (state, action) => {
      const storeDay = action.payload.storeDay;
      // console.log("storeDay", storeDay);
      state.storeDay = storeDay;
    },
  },
});

export const {setStoreYear, setStoreMonth, setStoreDay, formattedDateSet} = dateSlice.actions;

// selecter
export const selectStoreYear = (state) => state.dateSlice.storeYear;
export const selectStoreMonth = (state) => state.dateSlice.storeMonth;
export const selectStoreDay = (state) => state.dateSlice.storeDay;

export const selectFormattedDate = (state) => {
  const year = state.dateSlice.storeYear;
  const month = state.dateSlice.storeMonth;
  const day = state.dateSlice.storeDay;
  return formattedDate(year, month, day);
};

// 사용법
// SelectBoxDate 컴포넌트 파일을 이용해서 store를 활용합니다.
// 컴포넌트에서 현재의 날짜를 store에 저장합니다.

// import {formattedDate} from "@/utils/fommatDate";
// import {useSelector} from "react-redux";
// import {selectStoreDay, selectStoreMonth, selectStoreYear} from "@/store/modules/dateSlice";

// 현재 날짜, 월 , 일 불러오기
// const currentDateYear = useSelector(selectStoreYear);
// const currentDateMonth = useSelector(selectStoreMonth);
// const currentDateDay = useSelector(selectStoreDay);

// 현재 날짜를 불러오기
// const curDate = formattedDate(currentDateYear, currentDateMonth, currentDateDay);

export default dateSlice;
