import React from "react";
import { motion } from "framer-motion";
import ToolkitImage from "@/assets/images/main/main_toolkit.png";
import RectangleIcon from "@/assets/svg/main/rectangle.svg";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@/assets/svg/main/Chat.svg";
import useHoverAnimation from "@/hooks/useHoverAnimation";

const buttonData = [
  {
    title: "BMC",
    linkText: "바로가기",
    imgAlt: "스카우트 컨텐츠 바로가기",
    link: "/toolkit/bmcAbout",
  },
  {
    title: "온라인 사업 계획서",
    linkText: "바로가기",
    imgAlt: "스카우트 컨텐츠 바로가기",
    link: "/toolkit/chatbot",
  },
];

const toolkitContentsList = {
  id: 1,
  content:
    "BMC(Business Model Canvas)를 활용하여 창업 아이템을 체계적으로 구체화하는 방법을 소개합니다. 학생들은 BMC의 각 요소(고객 세그먼트, 가치 제안, 수익 모델 등)를 채워가며 아이디어의 시장성과 사업 모델을 명확히 이해할 수 있습니다.",
};

export default function ToolkitContent({ data }) {
  const navigate = useNavigate();
  const animation = useHoverAnimation();

  const handleNavToLink = (link) => {
    navigate(link);
  };

  return (
    <div className={"tab_content_wrapper"}>
      <div className={"nav_btn_box"}>
        <button className="nav_btn" onClick={() => navigate("/toolkit/bmcAbout")}>
          <span>바로가기</span>
          <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
        </button>
      </div>

      <div className={"main_content_box"}>
        <motion.div className={"content_img_box"} {...animation}>
          <img src={ToolkitImage} alt={""} />
        </motion.div>

        <div className={"consortium_content_box"}>
          <div>
            <p>
              <img src={RectangleIcon} alt={""} />
              <span>주요 성과수치</span>
            </p>

            <ul>
              {data.data.map((content, idx) => (
                <motion.li key={idx} {...animation}>
                  <span>-</span>
                  <span>{content}</span>
                </motion.li>
              ))}
            </ul>
          </div>

          <div className={"feature_content_box"}>
            <p>
              <img src={RectangleIcon} alt={""} />
              <span>관련 기능</span>
            </p>

            <div className={"feature_btn_group"}>
              {buttonData.map((button, index) => (
                <motion.button
                  {...animation}
                  key={index}
                  onClick={() => handleNavToLink(button.link)}
                >
                  <span>{button.title}</span>
                  <span className="nav_btn">
                    <span>{button.linkText}</span>
                    <img src={RightButton} alt={button.imgAlt} />
                  </span>
                </motion.button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <motion.div className={"tab_text_box"} {...animation}>
        <img src={ChatIcon} />
        <div>
          <p style={{ marginBottom: "16px" }}>
            계획단계에 머무른 창업을 구체화 단계까지 끌어 올릴 수 있도록 도움을 줄 수 있는
            도구들입니다.
          </p>
          <p>
            고객에게 어떤 가치를 전달하고, 어떻게 수익을 창출할 것인지 한눈에 알 수 있는
            &apos;비즈니스 모델 캔버스(BMC: Business Model Canvas)&apos;, 내 사업아이템 확립 및 실현
            가능성을 점검하고, 내·외부 다양한 목적으로 활용이 가능한 &apos;온라인 사업계획서&apos;
            등을 활용할 수 있습니다.
          </p>
        </div>
      </motion.div>

      {/*<SwiperContents list={toolkitContentsList} />*/}
    </div>
  );
}
