// lib
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import AOS from "aos";
// layout
import Header from "@/components/common/header/Header";
import Footer from "@/components/common/footer/Footer";
import MainRoutes from "@/routes/MainRoutes";
import { getMyPageUserInfo } from "@/api/myPage";
import { setUserDetailInfo } from "@/store/modules/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getTokenCookies } from "@/utils/cookies";
import { confirmCheckStudentMajor } from "@/utils/common";

// import NavigateTransition from "@/components/common/navigateTransition/NavigateTransition";

function App() {
  // init effect
  useEffect(() => {
    // 앱 실행과 동시에 AOS init 설정되도록(전역 설정)
    AOS.init({ duration: 500 });
  }, []);

  return (
    <BrowserRouter>
      {/* Header */}
      <Header />
      {/* modalContent */}
      <div id="content">
        <MainRoutes />
      </div>
      {/* Footer */}
      <Footer />
      {/* toast alert _ 우측 상단에 위치 _ 여러번 가능 */}
      <ToastContainer
        position="top-right" // 알람 위치 지정
        autoClose={3000} // 자동 off 시간
        hideProgressBar={false} // 진행시간바 숨김
        closeOnClick={true} // 클릭으로 알람 닫기
        rtl={false} // 알림 좌우 반전
        pauseOnFocusLoss // 화면을 벗어나면 알람 정지
        draggable // 드래그 가능
        pauseOnHover // 마우스를 올리면 알람 정지
        theme="light"
        // limit={1} // 알람 개수 제한
      />
    </BrowserRouter>
  );
}

export default App;
