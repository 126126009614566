import "@/components/Lms/chasi/EducationChasi.css";
// lib
import React, { useState } from "react";
import uuid from "react-uuid";
// images
import icon_folder from "@/assets/svg/icon_folder.svg";
import icon_chasi_type_onoff from "@/assets/svg/icon_onoff.svg";
import icon_chasi_type_video from "@/assets/svg/icon_chasi_type_video.svg";
import icon_chasi_type_offline from "@/assets/svg/icon_chasi_type_offline.svg";
import icon_chasi_type_realtime from "@/assets/svg/icon_chasi_type_realtime.svg";
// hooks
import useResponsive from "@/hooks/useResponsive";
import useFormatDate from "@/hooks/useFormatDate";

// * 프로그램 소개 페이지에서 모달 - 내용 중 차시 - modal modalContent
export default function EducationCuriDetail({ curiData }) {
  const getEduTypeVal = (eduType) => {
    switch (eduType) {
      case 1:
        return { label: "온라인 교육", icon: icon_chasi_type_video };
      case 2:
        return { label: "오프라인 교육", icon: icon_chasi_type_offline };
      case 3:
        return { label: "온오프통합 교육", icon: icon_chasi_type_onoff };
      case 4:
        return { label: "실시간 화상교육", icon: icon_chasi_type_realtime };
      default:
        return { label: "온라인 교육", icon: icon_chasi_type_video };
    }
  };

  const { isMobile, isTablet } = useResponsive();

  return (
    <section className="EducationChasi" style={{ marginBottom: "0px" }}>
      <div className="education_list_wrapper_forECD">
        <div className="education_category_list">
          <div className="category_header">
            <div className="edu_header_left">
              <img className="icon_folder" src={icon_folder} alt="folder icon" />
              <h4>프로그램</h4>
            </div>
          </div>
          {curiData?.modalChasiData?.map((item, index) => {
            const eduTypeVal = getEduTypeVal(item.chasiContentType);
            return (
              <div key={uuid()} className="chasi_item">
                <div className="chasi_list_header">
                  <div className="chasi_list_title">
                    <div>
                      <span className="chasi">{`${index + 1}차시`}</span>
                      <span className="edu_type">
                        <img
                          className="edu_type_icon"
                          src={eduTypeVal.icon}
                          alt="icon_chasi_type"
                        />
                        <span>{eduTypeVal.label}</span>
                      </span>
                    </div>

                    <span className="title">{item.chasiName}</span>
                  </div>
                </div>
                <div className="chasi_modal_list_body">
                  <div
                    className={item.chasiExp ? "chasi_content_detail" : "chasi_content_no_detail"}
                  >
                    {item.chasiExp ? `${item.chasiExp}` : ""}
                  </div>

                  {(item?.chasiContentType === 2 || item?.chasiContentType === 3) && (
                    <div className="off_schedule_box">
                      · 오프라인 일정 :
                      <span className="off_date">
                        {console.log("off item", item)}
                        {useFormatDate(item?.offlineStart, "yyyy년 MM월 dd일 HH:mm")} -
                        {useFormatDate(item?.offlineEnd, "HH:mm")}
                      </span>
                    </div>
                  )}

                  {item?.chasiContentType === 4 && (
                    <div className="off_schedule_box">
                      · 실시간 화상교육 일정 :
                      <span className="off_date">
                        {useFormatDate(item?.webexStart, "yyyy년 MM월 dd일 HH:mm")} -
                        {useFormatDate(item?.webexEnd, "HH:mm")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
