// yyyy-mm-dd 형식 맞추기 ( 2020-1-1 => 2020-01-01 )
import { format } from "date-fns";
import { padFillZero } from "@/utils/common";

export const formattedDate = (Year, Month, Day) => {
  const formattedMonth = padFillZero(Month);
  const formattedDay = padFillZero(Day);
  return `${Year}-${formattedMonth}-${formattedDay}`;
};

/**
 * @desc (yyyy-MM-dd) 또는 (Date) 형식의 데이터를 매개변수에 넣으면 하루를 더한 날짜를 "yyyy-MM-dd" 형태로 변환하여 반환
 * @param { Date || string } date
 * @return "yyyy-MM-dd"
 * */
export const formatTomorrow = (date) => {
  let convertDate = new Date(date);
  convertDate = convertDate.setDate(convertDate.getDate() + 1);
  return format(convertDate, "yyyy-MM-dd");
};

/**
 * @desc (yyyy-MM-dd) 또는 (Date) 형식의 데이터를 매개변수에 넣으면 하루를 더한 날짜를 "yyyy-MM-dd" 형태로 변환하여 반환
 * @param { Date || string } date
 * @return "yyyy-MM-dd"
 * */
export const formatYesterday = (date) => {
  let convertDate = new Date(date);
  convertDate = convertDate.setDate(convertDate.getDate() - 1);
  return format(convertDate, "yyyy-MM-dd");
};

/**
 * @desc (yyyy-MM-dd) 또는 (Date) 형식의 데이터를 매개변수에 넣으면 하루를 더한 날짜를 "yyyy-MM-dd" 형태로 변환하여 반환
 * @param { Date || string } date
 * @return "yyyy-MM-dd"
 * */
export const formatAddOneYear = (date) => {
  let convertDate = new Date(date);
  convertDate = convertDate.setFullYear(convertDate.getFullYear() + 1);
  return format(convertDate, "yyyy-MM-dd");
};
