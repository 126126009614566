import { ReactComponent as CloseIcon } from "@/assets/svg/close_white.svg";

// * TopSubTitle 대신 사용 - 모달 헤더 부분
const ModalHeader = ({
  modalTitle = "모달 제목",
  onClose,
  includesClassName,
  headerContainerStyle = {},
  headerTitleStyle = {},
  headerCloseIconStyle = {},
}) => {
  return (
    <div className={`modal_header ${includesClassName}`} style={headerContainerStyle}>
      <div className={`modal_header_inner ${includesClassName}`}>
        {/* 모달 타이틀 */}
        <div className={`modal_title ${includesClassName}`} style={headerTitleStyle}>
          {modalTitle}
        </div>

        {/* close icon */}

        <CloseIcon
          width={"16px"}
          height={"16px"}
          className={`modal_close_button ${includesClassName}`}
          stroke="#000"
          style={headerCloseIconStyle}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ModalHeader;
