import React, { useEffect } from "react";
import "./TabContent.css";
import { motion } from "framer-motion";

import ConsortiumMap from "@/assets/images/main/main_map.png";
import RectangleIcon from "@/assets/svg/main/rectangle.svg";
import SwiperContents from "@/pages/main/renewal/main-content/components/SwiperContents";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import ChatIcon from "@/assets/svg/main/Chat.svg";
import { useNavigate } from "react-router-dom";
import useHoverAnimation from "@/hooks/useHoverAnimation";

const contentsList = {
  id: 1,
  content:
    "SCOUT 충청권사업단 주도하에 “제1회 2024 SCOUT 충청권 학생창업 경진대회”가 2024년 11월 27일 개최됩니다. 충청권 대학간 창업 연계를 강화하여 창업교육 격차를 해소한다",
};

function ConsortiumContent({ data }) {
  const navigate = useNavigate();
  const animation = useHoverAnimation();

  return (
    <div className={"tab_content_wrapper"}>
      <div className={"nav_btn_box"}>
        <button
          className="nav_btn"
          onClick={() => navigate("/about/consortium?univ=capital&tab=일반대")}
        >
          <span>바로가기</span>
          <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
        </button>
      </div>

      <div className={"main_content_box"}>
        <motion.div className={"content_img_box"} {...animation}>
          <img src={ConsortiumMap} alt={""} />
        </motion.div>

        <div className="consortium_content_box">
          <p>
            <img src={RectangleIcon} alt={""} />
            <span>주요 성과수치</span>
          </p>

          <motion.ul>
            {data.data.map((content, idx) => (
              <motion.li key={idx} {...animation}>
                <span>-</span>
                <span>{content}</span>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </div>

      <motion.div className={"tab_text_box"} {...animation}>
        <img src={ChatIcon} />
        {/*<div dangerouslySetInnerHTML={{ __html: contentsList.content }} />*/}
        <div>
          <p style={{ marginBottom: "16px" }}>
            각 권역 내 대학을 중심으로 창업 생태계를 활성화 시키고, 지역 내 기업 및 기관과 함께
            협업체계를 구축중인 선도대학입니다.
          </p>
          <p>
            총 5개의 권역, 44개의 대학이 참여하고 있으며, 대학별 상세페이지를 통해 제공 중인 모든
            정보를 확인할 수 있습니다. 현재까지 개설된 프로그램부터 등록된 창업교육 콘텐츠, 창업교육
            활성화를 통해 달성한 주요 우수성과, 각 대학 출신 우수창업기업 등을 확인할 수 있습니다.
          </p>
        </div>
      </motion.div>

      {/*컨텐츠 스와이퍼*/}
      {/*<SwiperContents list={contentsList} />*/}
    </div>
  );
}

export default ConsortiumContent;
