import { createSlice } from "@reduxjs/toolkit";
import { getTokenCookies, removeTokenCookies, setTokenCookies } from "@/utils/cookies";
import { parseJWT } from "@/utils/JWT";

// Redux에서 관리할 초기 상태
const initialState = {
  userDetailInfo: null,
  accessToken: "",
  memberInfo: null,
  userInfo: null,
};
// ! 이 부분 보기 -> 변경해서 사용
const authSlice = createSlice({
  // ! 함수에 auth와
  name: "authSlice", // ! name : auth  --> 이름 동일하게 지어줘야됨
  initialState,
  reducers: {
    setAuth: (state, { payload: accessToken }) => {
      const userInfo = parseJWT(accessToken);
      state.accessToken = accessToken;
      state.userInfo = userInfo;
    },
    // action 생성
    login: (state, { payload }) => {
      const {
        data: { accessToken, refreshToken },
      } = payload;
      const userInfo = parseJWT(accessToken);
      setTokenCookies({ refreshToken, accessToken, userInfo });
      state.accessToken = accessToken;
      state.userInfo = userInfo;
    },
    logout: (state) => {
      // 쿠키를 통해 중복 프로세스 방지
      const { refreshToken, accessToken, userInfo } = getTokenCookies();
      if (!refreshToken || !accessToken || !userInfo) return;

      // 토큰이 소실되었을 때
      window.location.href = "/auth/login"; // 로그인 페이지로 이동
      state.userDetailInfo = initialState.userDetailInfo;
      state.accessToken = "";
      state.userInfo = null;
      removeTokenCookies();
      alert("로그아웃 되었습니다.");
    },
    setMemberInfo: (state, { payload }) => {
      state.memberInfo = payload;
    },
    setUserDetailInfo: (state, { payload }) => {
      state.userDetailInfo = payload;
    },
  },
});

export const { setUserDetailInfo, setAuth, login, logout, setMemberInfo } = authSlice.actions;
export default authSlice;
