import { createSlice } from "@reduxjs/toolkit";

const managementModalSlice = createSlice({
  name: "managementModalSlice",
  // 이니셜 스테이트
  initialState: {
    // modal title
    modalTitle: "",

    // * modal types
    // * "신청현황"
    // * "출결관리"
    // * "출결관리_차시_상세"
    // * "설문관리"
    // * "설문관리_미리보기"
    // * "설문관리_에디트"
    // * "설문관리_에디트_미리보기"
    // * "설문관리_결과조회"
    managementType: "",

    // * mini modal types
    // * "설문결과조회_주관식답변전체보기"
    // * "설문결과조회_객관식항목전체보기"
    miniModalType: "",

    // approvals
    selectedStudentList: [],
    participantStudentList: [],

    // todo: 추 후 페이지네이션 생기면 아마 필요없을 것임
    dataIndex: 0, // dataIndex
  },
  reducers: {
    // ! state reset
    resetManagementModalState: (state) => {
      state.modalTitle = "";
      state.managementType = "";
      state.miniModalType = "";
      state.selectedStudentList = [];
      state.participantStudentList = [];
      state.dataIndex = 0;
    },

    /** Modals */
    // ! set modalTitle 모달 상단 타이틀
    setModalTitle: (state, { payload }) => {
      state.modalTitle = payload;
    },
    // ! set ManagementType 관리 타입
    setManagementType: (state, { payload }) => {
      state.managementType = payload;
    },
    // ! set mini modal 미니모달 타입
    setMiniModalType: (state, { payload }) => {
      state.miniModalType = payload;
    },

    // ! set data index 클릭한 테이블 데이터의 index
    setDataIndex: (state, { payload }) => {
      state.dataIndex = payload;
    },

    /** Approvals */
    // ! 선택된 학생 배열 세팅
    setSelectedStudentList: (state, { payload }) => {
      state.selectedStudentList = payload;
    },
    // ! 강의에 참여한 학생 리스트 세팅
    setParticipantStudentList: (state, { payload }) => {
      state.participantStudentList = payload;
    },
  },
});

export const {
  setMiniModalType,
  setParticipantStudentList,
  setSelectedStudentList,
  setDataIndex,
  setManagementType,
  resetManagementModalState,
  setModalTitle,
} = managementModalSlice.actions;

export default managementModalSlice;
