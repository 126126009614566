import React, { useEffect, useState } from "react";
import "./MainTabContent.css";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import ConsortiumContent from "@/pages/main/renewal/main-content/tab-content/ConsortiumContent";
import ProgramContent from "@/pages/main/renewal/main-content/tab-content/ProgramContent";
import ToolkitContent from "@/pages/main/renewal/main-content/tab-content/ToolkitContent";
import LocalHeroContent from "@/pages/main/renewal/main-content/tab-content/LocalHeroContent";
import EducationContent from "@/pages/main/renewal/main-content/tab-content/EducationContent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import ArrowLeftIcon from "@/assets/svg/main/btn_arrow_left.svg";
import ArrowRightIcon from "@/assets/svg/main/btn_arrow_r.svg";
import PlayingIcon from "@/assets/svg/main/icon_playing.svg";
import PausedIcon from "@/assets/svg/main/icon_paused.svg";
import useResponsive from "@/hooks/useResponsive";
import { getConsortiumData } from "@/api/main/main";

export default function MainTabContent() {
  const { isMobile, isTablet } = useResponsive();

  const [activeTab, setActiveTab] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true); // 오토플레이 상태 저장
  const [data, setData] = useState([]);

  const tabs = [
    {
      id: 0,
      label: "권역별 선도대학",
      content: <ConsortiumContent data={data[0] || {}} />, // 기본값으로 빈 객체 전달
    },
    {
      id: 1,
      label: "창업교육 프로그램",
      content: <ProgramContent data={data[1] || {}} />,
    },
    {
      id: 2,
      label: "창업교육 툴킷",
      content: <ToolkitContent data={data[2] || {}} />,
    },
    {
      id: 3,
      label: "로컬실록지리지",
      content: <LocalHeroContent />,
    },
    {
      id: 4,
      label: "창업교육 컨텐츠",
      content: <EducationContent data={data[4] || {}} />,
    },
  ];

  const [slideIndex, setSlideIndex] = useState(0);
  const [swiper, setSwiper] = useState(null); // 슬라이드용

  const toggleAutoplay = () => {
    if (swiper) {
      if (isPlaying) {
        swiper.autoplay.stop(); // 오토플레이 정지
      } else {
        swiper.autoplay.start(); // 오토플레이 시작
      }
      setIsPlaying(!isPlaying); // 상태 반전
    }
  };

  const handlePrev = () => {
    if (swiper) {
      if (slideIndex === 0) {
        // 첫 번째 슬라이드일 때 마지막 슬라이드로 이동
        swiper.slideTo(tabs.length - 1);
        setSlideIndex(tabs.length - 1);
        setActiveTab(tabs.length - 1);
      } else {
        swiper.slidePrev();
        setSlideIndex(swiper.realIndex);
        setActiveTab(swiper.realIndex);
      }
    }
  };

  const handleNext = () => {
    if (swiper) {
      if (slideIndex === tabs.length - 1) {
        // 마지막 슬라이드일 때 첫 번째 슬라이드로 이동
        swiper.slideTo(0);
        setSlideIndex(0);
        setActiveTab(0);
      } else {
        swiper.slideNext();
        setSlideIndex(swiper.realIndex);
        setActiveTab(swiper.realIndex);
      }
    }
  };

  const fetchConsortiumData = async () => {
    try {
      const { data } = await getConsortiumData(); // `axios`는 응답 데이터를 `data` 속성에 포함

      setData(data);
      console.log(data, "펫치");
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
    }
  };

  useEffect(() => {
    fetchConsortiumData();
  }, []);

  return (
    <div className={"main_tab_wrapper"}>
      {data.length === 0 ? (
        <div>Loading...</div> // 데이터가 없을 때 로딩 표시
      ) : (
        <>
          <div className={"tab_box"}>
            {isMobile || isTablet ? (
              <div className={"mobile_tab_title"}>
                <h3>{tabs[activeTab].label}</h3>
              </div>
            ) : (
              <div className={"tab_btn_box"}>
                {tabs.map((tab) => (
                  <button
                    className={`tab_btn ${activeTab === tab.id ? "active_tab" : ""}`}
                    key={tab.id}
                    onClick={() => {
                      setActiveTab(tab.id);
                      swiper?.slideToLoop(tab.id);
                    }}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            )}

            {/* 페이지네이션 */}
            <div className={"swiper_pagination"}>
              <div className={"swiper_btn_group"}>
                <div className={"swiper_page"}>
                  <span className={"current"}>{slideIndex + 1}</span>
                  <span>/</span>
                  <span className={"page_length"}>{tabs.length}</span>
                </div>
                <div className={"play_status"}>
                  <button onClick={toggleAutoplay}>
                    <img src={isPlaying ? PlayingIcon : PausedIcon} alt="Toggle Autoplay" />
                  </button>
                </div>
                <span style={{ cursor: "pointer" }} onClick={handlePrev}>
                  <img src={ArrowLeftIcon} alt={""} />
                </span>
                <span style={{ cursor: "pointer" }} onClick={handleNext}>
                  <img src={ArrowRightIcon} alt={""} />
                </span>
              </div>
            </div>
          </div>

          <Swiper
            modules={[Navigation, Autoplay]}
            autoplay={{ delay: 3500, disableOnInteraction: false }}
            spaceBetween={30}
            slidesPerView={1}
            speed={1500}
            loop={false}
            onSlideChange={(swiper) => {
              setSlideIndex(swiper.realIndex);
              setActiveTab(swiper.realIndex);
            }}
            onSwiper={(e) => setSwiper(e)}
          >
            {tabs.map((tab) => (
              <SwiperSlide key={tab.id}>{tab.content}</SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
}
