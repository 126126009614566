import React, { useEffect, useState } from "react";
import "./MainTopContent.css";
import "@/components/Lms/cardSection/EducationCardModal";
import ScoutLogo from "@/assets/images/main/scout_logo_illust 1.png";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import ThumbNail from "@/assets/svg/main/card_thumbnail.png";
import Poster from "@/assets/svg/main/poster.jpg";
import CarouselComponent from "@/pages/main/renewal/top-content/carousel/Carousel";
import CardContents from "@/pages/main/renewal/top-content/components/CardContents";
import { useNavigate } from "react-router-dom";
import Modal from "@/components/common/modals/Modal";
import { getIntroduceListModal } from "@/api/lms";
import calender_navy from "@/assets/svg/calender_navy.svg";
import useFormatDate from "@/hooks/useFormatDate";
import { getTokenCookies } from "@/utils/cookies";
import EducationCuriDetail from "@/components/Lms/curriculum/modal/EducationCuriDetail";
import useResponsive from "@/hooks/useResponsive";
import { getNewestProgram } from "@/api/main/main";

export default function MainTopContent() {
  const { isMobile } = useResponsive();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [programCardData, setProgramCard] = useState(null);

  const [loading, setLoading] = useState(true);

  const fetchNewestProgram = async () => {
    setLoading(true); // 데이터 로드 시작
    try {
      const { data } = await getNewestProgram();
      setProgramCard(data[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // 데이터 로드 완료
    }
  };

  const fetchModalContent = async () => {
    try {
      const { data } = await getIntroduceListModal({
        projectNo: programCardData.project_no,
        eduNo: programCardData.edu_no,
      });
      console.log(data);
      setModalContent(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenModal = async () => {
    if (loading) {
      console.error("Loading data, please wait.");
      return; // 로딩 중일 때는 함수 종료
    }

    // programCardData가 아직 로드되지 않았을 때
    if (!programCardData) {
      console.error("Program data is not loaded yet.");
      return; // 데이터가 없으면 함수 종료
    }

    const { project_no, edu_no } = programCardData;

    // project_no 또는 edu_no가 없을 때
    if (!project_no || !edu_no) {
      console.error("Project number or education number is missing.");
      return;
    }

    setIsModalOpen(true); // 모달 열기
  };

  const cards = [
    {
      key: "카드1",
      content: (
        <CardContents
          programData={programCardData}
          className={"card_box"}
          title={"[충남대] B.O.S.S특강"}
          subTitle={"프로그램"}
          description={"대학 중심 지역 협업체계 강화"}
          button={false}
          showModal={true} // 모달을 표시할 카드에서 true 설정
          handleOpenModal={() => handleOpenModal("대학 중심 지역 협업체계 강화")} // 모달 열기
        />
      ),
    },
    {
      key: "카드2",
      content: (
        <CardContents
          className={"card_box"}
          title={
            "[로컬창업교육포럼]2024 로컬 기업가정신 및 창업교육 운영 현황 조사_KoEF 이윤석 본부장님"
          }
          subTitle={"창업교육 콘텐츠"}
          description={"2024.11.19"}
          handleNavLink={() => navigate("/board/notice/2034/40")}
        />
      ),
    },
    {
      key: "카드3",
      content: (
        <CardContents
          className={"card_box contents_box"}
          title={"2023 산학협력 EXPO"}
          subTitle={"창업교육 콘텐츠"}
          description={"2024.07.15"}
          imgSrc={ThumbNail}
          handleNavLink={() => navigate("/board/eduContents/videoRef/2117/41")}
        />
      ),
    },
    {
      key: "갤러리",
      content: (
        <CardContents
          className={"card_box"}
          title={"2024 창업교육정책세미나"}
          subTitle={"창업교육 콘텐츠"}
          description={"2024.11.19"}
          handleNavLink={() => navigate("/board/eduContents/gallery/2151/322")}
          imgSrc={Poster}
        />
      ),
    },
    {
      key: "공지사항",
      content: (
        <CardContents
          className={"card_box"}
          title={<div>창업교육 혁신 선도대학 SCOUT 통합 플랫폼 사용자 매뉴얼 배포</div>}
          subTitle={"공지사항"}
          description={"2024.05.21"}
          handleNavLink={() => navigate("/board/notice/2036/40")}
        />
      ),
    },
    {
      key: "기타",
      content: (
        <CardContents
          className={"card_box"}
          title={<div>2024 산학연협력 EXPO - 공식 홍보영상</div>}
          subTitle={"기타"}
          description={"2024.10.5"}
          url={"https://www.youtube.com/watch?v=Ynq_aX-dXGM"}
          button={false}
        />
      ),
    },
  ];

  const { userInfo } = getTokenCookies();
  const isBtnHide = location.pathname === "/education/course";

  const handleApplyList = () => {
    if (new Date(modalContent?.modalBaseData?.proposeEdate).getTime() < new Date().getTime())
      return;

    if (!userInfo?.cNo && confirm("로그인이 필요한 페이지입니다. 로그인 페이지로 이동합니다.")) {
      navigate("/auth/login");
    }
    if (userInfo?.cNo && confirm("신청을 위해 프로그램 신청 페이지로 이동합니다.")) {
      navigate("/education/list");
    }
  };

  useEffect(() => {
    fetchNewestProgram();
  }, []);

  useEffect(() => {
    if (programCardData?.project_no && programCardData?.edu_no) {
      fetchModalContent();
    }
  }, [programCardData]); // programCardData가 업데이트될 때마다 fetchModalContent 호출

  return (
    <div className={"main_top_wrapper"}>
      {loading ? (
        <div>...loading</div>
      ) : (
        <div className={"main_top_inner"}>
          <div className={"scout_des_wrapper"} data-aos="fade-up" data-aos-duration="1500">
            <img src={ScoutLogo} alt={""} />

            <div className={"scout_description"}>
              <span>
                {isMobile ? (
                  <>
                    지역 기반의 효율적인 <br /> 대학 창업교육 생태계 기반 구축
                  </>
                ) : (
                  <>지역 기반의 효율적인 대학 창업교육 생태계 기반 구축</>
                )}
              </span>
              <p className={"title"}>
                <span style={{ color: "#024C8C" }}>S</span>
                <span style={{ color: "#4B9737" }}>CO</span>
                <span style={{ color: "#F4BA19" }}>U</span>
                <span style={{ color: "#C8241D" }}>T</span> 창업교육 혁신 선도대학
              </p>
            </div>
          </div>

          <div className={"scout_content_wrapper"} data-aos="fade-up" data-aos-duration="1500">
            <div className={"content_top_box"}>
              <p className={"title"}>주요 소식</p>
            </div>

            <CarouselComponent
              cards={cards}
              height="216px"
              width="100%"
              margin="0 auto"
              offset={2}
              showArrows={false}
            />
          </div>
        </div>
      )}

      {/* 모달 추가 */}
      {isModalOpen && (
        <Modal
          isCustomScroll
          includesClassName="course_info"
          setIsOpen={setIsModalOpen}
          isOpen={isModalOpen}
          modalTitle="상세일정"
        >
          <div className="eduModal_container">
            <span>{modalContent?.modalBaseData?.eduType}</span>
            <h3>{modalContent?.modalBaseData?.eduName}</h3>

            <p className="subTitle">
              {/* 권역, 대학교 */}
              <span>
                {`${modalContent?.modalBaseData?.educatorFieldName} ${modalContent?.modalBaseData?.educatorUnivName}`}
              </span>

              {/* 교수자 이름 */}
              <span className="edu_card_modal_professor_name">
                {modalContent?.modalBaseData?.educatorName} 교수
              </span>

              {/* 공동 교수자 카운트 ( 교수자 툴팁 ) */}
              {!!modalContent?.joinEducatorInfo?.cnt && (
                <span data-tooltip-id="edu_project_join_professor">
                  {"외 "}
                  <span className="edu_card_modal_join_professor_cnt">
                    {modalContent?.joinEducatorInfo?.cnt}명
                  </span>
                </span>
              )}
            </p>

            <div className="eduModal_contents">
              <div className="studentInfo">
                {/* 인원수 div 없애면서 인라인 스타일로 마진 값 없앰 */}
                <div className="studyPeriod" style={{ marginLeft: "0px" }}>
                  <img src={calender_navy} alt="calender_navy" />
                  <p>신청기간</p>
                  <span>{`${useFormatDate(
                    modalContent?.modalBaseData?.proposeSdate,
                    "yyyy.MM.dd",
                  )} ~ ${useFormatDate(
                    modalContent?.modalBaseData?.proposeEdate,
                    "yyyy.MM.dd",
                  )}`}</span>
                </div>
                <div className="studyPeriod" style={{ marginLeft: "0px" }}>
                  <img src={calender_navy} alt="calender_navy" />
                  <p>학습기간</p>
                  <span>{`${useFormatDate(
                    modalContent?.modalBaseData?.studySdate,
                    "yyyy.MM.dd",
                  )} ~ ${useFormatDate(
                    modalContent?.modalBaseData?.studyEdate,
                    "yyyy.MM.dd",
                  )}`}</span>
                </div>

                {/* 교수자일 경우에만 신청하기 버튼 삭제 */}
                {/* proposeEdate가 넘었을 때 신청하기 버튼 disabled */}
                {/* new Date(curiData.modalBaseData.proposeEdate가).getTime() < new Date().getTime() */}
                {isBtnHide && userInfo?.cGubun !== 1 && (
                  <button
                    className={
                      new Date(modalContent?.modalBaseData?.proposeEdate).getTime() <
                      new Date().getTime()
                        ? "modal_apply_btn"
                        : "modal_disabled_btn"
                    }
                    onClick={handleApplyList}
                  >
                    신청하기
                  </button>
                )}
              </div>
            </div>

            {/* Divider */}
            <div className="edu_card_modal_divider" />

            {/* 수료기준 - 고정값*/}
            <div className="CompletionCriteria">
              <dl className="description_box">
                <dt>수료기준</dt>
                <dd>
                  <ul>
                    <li>
                      <span>온라인 교육 :</span> 학습 기간 내에 교육 영상의 80% 이상 시청해야
                      출석으로 인정됩니다.
                    </li>
                    <li>
                      <span>오프라인 교육 :</span> 공지 확인 후 오프라인 교육에 참여해야 출석으로
                      인정됩니다.
                    </li>
                    <li>
                      <span>온오프 혼합교육 :</span>
                      공지 확인 후 오프라인 교육에 참여해야 출석으로 인정됩니다.
                    </li>
                    <li>
                      <span>실시간 화상 교육 :</span> 해당 실시간 화상 교육에 참여해야 출석으로
                      인정됩니다.
                    </li>
                    <li>
                      <span>* 수료 기준은 프로그램마다 상이할 수 있습니다.</span>
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl className="description_box">
                <dt>수료증</dt>
                <dd>
                  <ul>
                    <li>모든 강좌의 수료기준 충족 시 수료증 출력이 가능합니다.</li>
                  </ul>
                </dd>
              </dl>
            </div>

            {/* 프로그램 내용 */}
            <EducationCuriDetail curiData={modalContent} />
          </div>
        </Modal>
      )}
    </div>
  );
}
