import { createSlice } from "@reduxjs/toolkit";

const surveySlice = createSlice({
  name: "surveySlice",
  // 이니셜 스테이트
  initialState: {
    isSurvey: false, // 설문조사 여부
    surveyNo: 0, // 만족도조사 설문지 pk
    eduProjectNo: 0, // 프로젝트 pk
    educatorNo: 0, // 교수자 pk
    isAnonymous: false, // 익명설문 여부
    surveyQuestionInfo: [], // 생성될 설문지 객체

    // ! 서버로부터 전달받은 데이터를 캐싱 (프로그램관리 -> 설문 관리 -> 설문 미리보기에서 사용)
    // ? 하나의 state로 모두 처리 시, 수정 도중 취소할 경우 db에서 받은 오리진 데이터가 아닌 수정하다만 데이터가 보여지기 때문
    responseSurveyQuestionInfo: [],

    // ? db에 저장되어있는 origin 익명여부 보존
    responseIsAnonymous: false,

    // ? 교수자가 설문결과 조회 시 전역 상태로 관리하여 통합된 모달에서 활용
    surveyResultData: null,

    // ? 교수자가 설문결과 조회 시 객관식/주관식 전체보기 누르면 데이터를 저장함
    // ? (객관식/주관식 답변항목 전체를 미니모달에 띄워보여주기 위함)
    targetSurveyDetailTotalData: null,
  },
  reducers: {
    // ! reset survey
    resetSurvey: (state) => {
      state.isSurvey = false;
      state.surveyNo = 0;
      state.eduProjectNo = 0;
      state.educatorNo = 0;
      state.isAnonymous = false;
      state.surveyQuestionInfo = [];
      state.responseSurveyQuestionInfo = [];
    },

    /**------------------------ Primary keys ------------------------*/
    setSurveyNo: (state, { payload }) => {
      state.surveyNo = payload;
    },
    setEduProjectNo: (state, { payload }) => {
      state.eduProjectNo = payload;
    },
    setEducatorNo: (state, { payload }) => {
      state.educatorNo = payload;
    },

    /**------------------------ Type State ------------------------*/
    // ! set 설문조사 여부  state
    setIsSurvey: (state, { payload }) => {
      state.isSurvey = payload;
    },

    // ! set 익명 state
    setIsAnonymous: (state, { payload }) => {
      state.isAnonymous = payload;
    },

    // ! set 익명 state
    setResponseIsAnonymous: (state, { payload }) => {
      state.responseIsAnonymous = payload;
    },

    // ! 객관식 타입 변경
    setTargetQuestionChoiceType: (state, { payload }) => {
      const { id, questionType } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.map((item) => {
        if (item.id === id) {
          item.questionType = questionType;
        }
        return item;
      });
    },

    /**------------------------ Questions ------------------------*/

    // ! set surveyQuestionInfo
    setSurveyQuestionInfo: (state, { payload }) => {
      state.surveyQuestionInfo = payload;
    },

    setResponseSurveyQuestionInfo: (state, { payload }) => {
      state.responseSurveyQuestionInfo = payload;
    },
    setSurveyResultData: (state, { payload }) => {
      state.surveyResultData = payload;
    },

    // ! 설문 question 추가 리듀서
    addSurveyQuestionInfoItem: (state, { payload }) => {
      state.surveyQuestionInfo = [...state.surveyQuestionInfo, payload];
    },

    // ! 질문이름 변경
    setTargetQuestionName: (state, { payload }) => {
      const { id, value } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.map((item) => {
        if (item.id === id) {
          // 질문 타입에 따른 벨류 저장
          if (item.questionType.includes("choice")) item.choiceQuestionName = value;
          else item.textQuestionName = value;
        }
        return item;
      });
    },

    // ! 질문 question 삭제
    deleteTargetQuestion: (state, { payload }) => {
      const { id } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.filter((item) => item.id !== id);
    },

    /**------------------------ Options ------------------------*/

    // ! 항목 (option) 세팅 리듀서
    setTargetQuestionOptions: (state, { payload }) => {
      const { questionId, newOption } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.map((question) => {
        if (question.id === questionId) {
          question.questionOption = newOption;
        }
        return question;
      });
    },

    // ! 항목 (option) 추가 리듀서
    addTargetQuestionOptionItem: (state, { payload }) => {
      const { id, newOption } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.map((question) => {
        if (question.id === id) {
          question.questionOption = [...question.questionOption, newOption];
        }
        return question;
      });
    },

    // ! 항목 (option) 변경
    setTargetOptionName: (state, { payload }) => {
      const { questionId, optionId, value } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.map((item) => {
        if (item.id === questionId) {
          item.questionOption = item.questionOption.map((option) => {
            if (option.id === optionId) {
              option.optionName = value;
            }
            return option;
          });
        }
        return item;
      });
    },

    // ! 항목 (option) 삭제
    deleteTargetQuestionOption: (state, { payload }) => {
      const { questionId, optionId } = payload;

      state.surveyQuestionInfo = state.surveyQuestionInfo.map((item) => {
        if (item.id === questionId) {
          item.questionOption = item.questionOption.filter((option) => option.id !== optionId);
        }
        return item;
      });
    },

    // ! 특정 객관식 항목에 대한 데이터 (해당 항목을 선택한 유저를 식별)
    setTargetSurveyDetailTotalData: (state, { payload }) => {
      state.targetSurveyDetailTotalData = payload;
    },
  },
});

export const {
  setResponseIsAnonymous,
  setTargetQuestionOptions,
  setTargetSurveyDetailTotalData,
  setSurveyResultData,
  setResponseSurveyQuestionInfo,
  resetSurvey,
  setSurveyQuestionInfo,
  setIsSurvey,
  deleteTargetQuestionOption,
  setTargetOptionName,
  addTargetQuestionOptionItem,
  setTargetQuestionChoiceType,
  setIsAnonymous,
  setEducatorNo,
  addSurveyQuestionInfoItem,
  setSurveyNo,
  setEduProjectNo,
  setTargetQuestionName,
  deleteTargetQuestion,
} = surveySlice.actions;

export default surveySlice;
