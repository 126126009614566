import { createSlice } from "@reduxjs/toolkit";
import { consortiumData } from "@/mock/consortium-mock";

const universitySlice = createSlice({
  name: "universitySlice",
  initialState: {
    university: null,
    univNo: null,
  },
  reducers: {
    setUniversity(state, action) {
      state.university = action.payload;
    },
    setUnivNo(state, action) {
      state.univNo = action.payload;
    },
  },
});

// 액션 생성자 내보내기
export const { setUniversity, setUnivNo } = universitySlice.actions;

// 대학 정보를 찾는 셀렉터
export const selectUniversity = (state) => state.universitySlice.university;

// 대학 정보를 찾는 함수
export const findUniversityByNo = (univNo) => {
  for (const consortium of consortiumData) {
    const generalUniv = consortium.universities.general.find((univ) => univ.univNo === univNo);
    if (generalUniv) return { ...generalUniv, type: "general", region: consortium.region };

    const vocationalUniv = consortium.universities.vocational.find(
      (univ) => univ.univNo === univNo,
    );
    if (vocationalUniv) return { ...vocationalUniv, type: "vocational", region: consortium.region };
  }
  return null;
};

export default universitySlice;
