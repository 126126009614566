// 권역 데이터(edu_field)를 string 배열의 형태로 return 하는 함수
export const regionDataHandling = (data) => {
  if (typeof data !== "string") return [];
  const numbers = data?.split("|").map(Number);
  const regionMap = {
    11: "수도권",
    12: "충청권",
    13: "호남제주권",
    23: "호남제주권",
    14: "대경강원권",
    24: "대경강원권",
    15: "동남권",
    25: "동남권",
  };
  const regions = numbers
    .map((number) => regionMap[number])
    .filter((region) => region !== undefined);
  // 중복 데이터 제거
  const uniqueRegions = [...new Set(regions)];

  return uniqueRegions;
};
// ["수도권","충청권"]  -> 이렇게 string을 갖는 배열로 return
