import { AlarmJavaApi } from ".";

/**
 * @description SSE 알림 해제
 * @param  {Object} userId
 */
export function alarmDisconnect(userId) {
  return AlarmJavaApi.get("/disconnect", { params: { userId } });
}

/**
 * @description SSE 알림 리스트 가져오기
 * @param  {Object} userId
 */
export function getPlatformAlarmList(memberNo) {
  return AlarmJavaApi.get("/getplatformalarmlist", { params: { memberNo } });
}

/**
 * @description SSE 알림 읽기
 */
export function setAlarmIsRead(requestBody) {
  return AlarmJavaApi.post("/setalarmisread", requestBody);
}

/**
 * @description SSE 알림 읽기
 */
export function deleteAlarm(requestBody) {
  return AlarmJavaApi.post("/deletealarm", requestBody);
}
