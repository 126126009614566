// 날짜 데이터를 포매팅 해주는 함수 라이브러리
import {format, parseISO} from "date-fns";

// type의 기본값은 "yyyy.MM.dd HH:mm" 입니다.
function useFormatDate(date, type = "yyyy.MM.dd HH:mm") {
  let formattedDate = "";
  // Check if the date is in a valid format, e.g., "2023-11-20T12:34:56"
  if (date && !isNaN(Date.parse(date))) {
    formattedDate = format(parseISO(date), type);
  }
  return formattedDate;
}

export default useFormatDate;
/*
  사용 예시 :
    {useFormatDate(data[i].c_propose_s_date)}
  이런 식으로 함수처럼 사용하고 인자로 string값을 보내주면 된다. 

  * 서버에서 보내주는 date 데이터의 형태
  :  2024-02-13T00:00:00.000Z
  * return되는 데이터의 형태 
  :  2024-02-13
*/
