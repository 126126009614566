import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import EducationImage from "@/assets/images/main/main_education.png";
import RectangleIcon from "@/assets/svg/main/rectangle.svg";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import ChatIcon from "@/assets/svg/main/Chat.svg";
import useHoverAnimation from "@/hooks/useHoverAnimation";

const buttonData = [
  {
    title: "수업 연구자료",
    linkText: "바로가기",
    imgAlt: "스카우트 컨텐츠 바로가기",
    link: "/board/eduContents/researchRef?p=1",
  },
  {
    title: "동영상",
    linkText: "바로가기",
    imgAlt: "스카우트 컨텐츠 바로가기",
    link: "/board/eduContents/videoRef?p=1",
  },
  {
    title: "갤러리",
    linkText: "바로가기",
    imgAlt: "스카우트 컨텐츠 바로가기",
    link: "/board/eduContents/gallery?p=1",
  },
];

const eduContentsList = {
  id: 1,
  content:
    "창업 생태계의 구조와 창업자가 네트워킹을 통해 자원을 확보하는 방법에 대한 동영상 강의를 제공합니다. 창업 생태계의 다양한 구성 요소(투자자, 창업 지원기관, 멘토 등)를 이해하고, 네트워킹 전략을 통해 사업 자원을 어떻게 활용할 수 있는지 배우게 됩니다.",
};

export default function EducationContent({ data }) {
  const navigate = useNavigate();
  const animation = useHoverAnimation();

  const handleNavToLink = (link) => {
    navigate(link);
  };

  return (
    <div className={"tab_content_wrapper"}>
      <div className={"nav_btn_box"}>
        <button className="nav_btn" onClick={() => navigate("/board/eduContents/researchRef?p=1")}>
          <span>바로가기</span>
          <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
        </button>
      </div>

      <div className={"main_content_box"}>
        <motion.div className={"content_img_box"} {...animation}>
          <img src={EducationImage} alt={""} />
        </motion.div>

        <div className={"consortium_content_box"}>
          <div>
            <p>
              <img src={RectangleIcon} alt={""} />
              <span>주요 성과수치</span>
            </p>

            <ul>
              {data.data.map((content, idx) => (
                <motion.li key={idx} {...animation}>
                  <span>-</span>
                  <span>{content}</span>
                </motion.li>
              ))}
            </ul>
          </div>

          <div className={"feature_content_box"}>
            <p>
              <img src={RectangleIcon} alt={""} />
              <span>관련 기능</span>
            </p>

            <div className={"feature_btn_group"}>
              {buttonData.map((button, index) => (
                <motion.button
                  {...animation}
                  key={index}
                  onClick={() => handleNavToLink(button.link)}
                >
                  <span>{button.title}</span>
                  <span className="nav_btn">
                    <span>{button.linkText}</span>
                    <img src={RightButton} alt={button.imgAlt} />
                  </span>
                </motion.button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <motion.div className={"tab_text_box"} {...animation}>
        <img src={ChatIcon} />
        <div>
          <p style={{ marginBottom: "16px" }}>
            창업관련 다양한 콘텐츠를 확인할 수 있는 공간입니다.
          </p>
          <p>
            실제 프로그램을 진행하면서 사용했던 수업·연구자료부터 동영상, 사진 등 다양한 형태의
            콘텐츠를 무료로 제공합니다. 또한, 대학에서 제공했던 창업교육 콘텐츠나 외부 자료들도 함께
            확인할 수 있습니다.
          </p>
        </div>
      </motion.div>

      {/*<SwiperContents list={eduContentsList} />*/}
    </div>
  );
}
