import { createSlice } from "@reduxjs/toolkit";

export const newChasi = () => {
  return {
    chasiSequence: Date.now(),
    chasiName: "",
    chasiExp: "",
    chasiContentType: 1,
    chasiAssignmentInfo: [],
    eduVideoExistYN: "N",
    videoFileName: "",

    // ! 기획 변경으로 오프라인 교육도 동일하게 처리
    // isOfflineAssign: false,

    // 웹엑스 관련 객체 (개설 전 webexDate 는 삭제, webexStart,end는 가공)
    webexDate: "",
    webexStart: {},
    webexEnd: {},
    // 오프라인, 온오프 혼합교육 일정
    offlineDate: "",
    offlineStart: {},
    offlineEnd: {},
  };
};

const curriculumSlice = createSlice({
  name: "curriculumSlice",
  // 이니셜 스테이트
  initialState: {
    periodCourseMax: 100, // 학습기간을 기반으로 개설 가능한 오프라인 수업의 최대 개수를 지정 default: 100(넉넉히 잡아둠)
    isClickedPrev: false, // 기간을 지정하는 수업의 초기화를 알리기 위한 state
    isPrevChecked: false, // 기간을 지정하는 수업의 초기화를 알리기 위한 state
    periodCourseIndexArr: [], // 기간 지정 수업인 차시박스의 index array todo: 현재 오프라인 교육만
    editCurriculumInfo: [{ ...newChasi() }],
  },
  reducers: {
    // set 커리큘럼
    setEditCurriculumInfo: (state, { payload }) => {
      state.editCurriculumInfo = payload;
    },
    // set init 커리큘럼
    setInitEditCurriculumInfo: (state) => {
      state.editCurriculumInfo = [{ ...newChasi() }];
    },

    // step2에서 이전버튼 눌렀을때 true
    setIsClickedPrev: (state, { payload }) => {
      state.isClickedPrev = payload;
    },
    // 이전버튼을 누른 후 alert 창을 확인
    setIsPrevChecked: (state, { payload }) => {
      state.isPrevChecked = payload;
    },

    // 오프라인 수업인 차시의 index 배열 push
    pushPeriodCourseIndexArr: (state, { payload }) => {
      // 중복제거
      const setArr = new Set([...state.periodCourseIndexArr, payload]);
      state.periodCourseIndexArr = [...setArr];
    },
    slicePeriodCourseIndexArr: (state, { payload }) => {
      // 중복제거
      const filter = state.periodCourseIndexArr.filter((item) => item !== payload);
      console.log(filter);
      state.periodCourseIndexArr = [...filter];
    },
    // 오프라인 수업인 차시 index 배열 reset
    resetPeriodCourseIndexArr: (state) => {
      state.periodCourseIndexArr = [];
    },

    // 기간 설정이 필요한 수업 개설 수 제한을 위한 state todo: 현재 오프라인 교육만 ( 현재 미사용중 )
    setPeriodCourseMax: (state, { payload }) => {
      state.periodCourseMax = payload;
    },
  },
});

export const {
  setEditCurriculumInfo,
  setInitEditCurriculumInfo,
  setPeriodCourseMax,
  setIsClickedPrev,
  setIsPrevChecked,
  pushPeriodCourseIndexArr,
  slicePeriodCourseIndexArr,
  resetPeriodCourseIndexArr,
} = curriculumSlice.actions;

export default curriculumSlice;
