import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import "./Carousel.css";
import ArrowRightIcon from "@/assets/svg/main/btn_arrow_r.svg";
import ArrowLeftIcon from "@/assets/svg/main/btn_arrow_left.svg";

export default function CarouselComponent(props) {
  const table = props.cards.map((element, index) => {
    return { ...element };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  useEffect(() => {
    setGoToSlide(index);
  }, [index]);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <div style={{ width: props.width, height: props.height, margin: props.margin }}>
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        offsetFn={(offsetFromCenter) => {
          if (offsetFromCenter === 0) {
            return {
              opacity: 1,
              transform: "translateY(-50%) translateX(-50%) scale(1)",
              left: "50%",
            };
          }

          const isLeft = offsetFromCenter < 0;

          return {
            opacity: 1,
            // transform: isLeft
            //   ? "translateY(-50%) translateX(10%) scale(0.75)"
            //   : "translateY(-50%) translateX(0%) scale(0.75)",
            left: isLeft ? "40%" : "60%",
          };
        }}
      />

      <div className={"carousel_pagination"}>
        <div className={"carousel_bullet_group"}>
          {/*bullet*/}
          {cards.map((_, bulletIndex) => (
            <div
              key={bulletIndex}
              className={bulletIndex === index ? "bullet_active" : "bullet"}
            ></div>
          ))}
        </div>

        {/*페이지네이션*/}
        <div className={"carousel_btn_group"}>
          <span style={{ cursor: "pointer" }} onClick={handlePrev}>
            <img src={ArrowLeftIcon} alt={""} />
          </span>
          <div className={"carousel_page"}>
            <span className={"current"}>{index + 1}</span>
            <span>/</span>
            <span className={"page_length"}>{cards.length}</span>
          </div>
          <span style={{ cursor: "pointer" }} onClick={handleNext}>
            <img src={ArrowRightIcon} alt={""} />
          </span>
        </div>
      </div>
    </div>
  );
}
