import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules"; // 최신 Swiper 모듈에서 가져오기
import "swiper/css"; // 최신 스타일 경로
import "swiper/css/mousewheel"; // Mousewheel 스타일
import "./MainHomePage.css";

import HomeSecond from "./home/second/HomeSecond";

const MainHomePage = () => {
  return (
    <div className="main_home_page">
      {/* Swiper 컨테이너 */}
      <Swiper
        direction="vertical" // 세로 방향 슬라이더
        slidesPerView={1} // 한 번에 하나의 슬라이드
        spaceBetween={0} // 슬라이드 간 간격
        modules={[Mousewheel]} // Swiper 플러그인 추가
        mousewheel // 마우스 휠 이벤트 활성화
        className="main_home_page_swiper"
        speed={1200}
      >
        <SwiperSlide
          className="main_home_page_slide"
          style={{ backgroundColor: "#DEF0FF", color: "black" }}
        >
          <h1>SCOUT</h1>
        </SwiperSlide>
        <SwiperSlide className="main_home_page_slide">
          <HomeSecond />
        </SwiperSlide>
        <SwiperSlide
          className="main_home_page_slide"
          style={{ backgroundColor: "#fc6c7c", color: "white" }}
        >
          <h1>Page 3</h1>
        </SwiperSlide>
        <SwiperSlide
          className="main_home_page_slide"
          style={{ backgroundColor: "#435b71", color: "white" }}
        >
          <h1>Page 4</h1>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MainHomePage;
