import { auth, ComJavaApi, ComNodeApi, LmsJavaApi, LmsNodeApi } from "./index.js";

/**
 * @description 마이페이지 - LMS 목록 조회
 * @param {Number} memberNo - 로그인한 유저의 id
 * @param {Number} eduStatus - 1 진행중 , 2 진행완료
 */
export function getMyPageEducationList(requestData) {
  const { memberNo, eduStatus } = requestData;
  return LmsJavaApi.get("/getapprovedprojectlist", { params: { memberNo, eduStatus } });
}

/**
 * @description 마이페이지 - 유저 정보 불러오기
 * @param {number} no - 로그인 한 유저의 cNo
 */
export function getMyPageUserInfo(no) {
  return ComNodeApi.get("/member/memberview", { params: { no } });
}

/**
 * @description 마이페이지 - 유저 정보 비밀번호 수정하기
 * @param  {Object} requestData
 * @property {number} memberNo - 로그인 한 유저의 cNo
 * @property {string} pass - 현재 비밀번호
 * @property {string} password - 변경할 비밀번호
 * @property {string} password1 - 변경할 비밀번호 확인
 */
export function modifyUserPassword(requestData) {
  return auth.post("/setpassword", requestData);
}

/**
 * @description 마이페이지 - BMC 목록 조회
 * @param {number} memberNo - 로그인 한 유저의 cNo
 */
export function getMypageBMCList(requestData) {
  const { memberNo, keyword } = requestData;
  return ComNodeApi.get(`/member/mybmc`, { params: { memberNo, keyword } });
}

/**
 * @description 마이페이지 - BMC - lms연결된 목록 조회
 * @param {number} memberNo - 로그인 한 유저의 cNo
 */
export function getMypageLinkedBMCAllList(requestData) {
  const { memberNo, keyword } = requestData;
  return ComNodeApi.get(`/educator/entiremybmc`, { params: { memberNo, keyword } });
}

/**
 * @description 마이페이지 - BMC - lms연결된 목록 조회
 * @param {number} memberNo - 로그인 한 유저의 cNo
 */
export function getMypageLinkedBMCList(requestData) {
  const { projectNo, eduProjectNo, keyword } = requestData;
  return ComNodeApi.get(`/educator/mybmc`, { params: { projectNo, eduProjectNo, keyword } });
}

/**
 *
 * @description BMC 캔버스 삭제
 *  @param  {Object} requestData
 * @property {number} teamNo - bmc 팀 번호
 * @property {number} memberNo - 로그인 한 유저의 cNo
 */
export function deleteBmcCanvas(requestData) {
  return ComJavaApi.post(`/member/delbmc`, requestData);
}
