import "./eduProjectJoinProfessorTooltip.css";
import React, { useEffect } from "react";
import { Tooltip } from "react-tooltip";

export default function EduProjectJoinProfessorTooltip({
  listData,
  targetId = "edu_project_join_professor",
}) {
  useEffect(() => {
    console.log("조ㅓ인 에듀케어ㅣ터 !!", listData);
    console.log("targetId !!", targetId);
  }, []);
  return (
    <Tooltip
      id={targetId}
      clickable
      content={
        <ul className="join_professor_tooltip">
          {listData?.map((item, idx, arr) => (
            <li key={item.joinEducatorNo}>
              {/* items */}
              <div className="edu_card_modal_join_professor_tooltip_item">
                <p className="edu_card_modal_join_professor_tooltip_item_name">
                  {item.joinEducatorName}
                </p>
                <p>{item.joinEducatorFieldName}</p>
                <p>{item.joinEducatorUnivName}</p>
              </div>

              {/* divider */}
              {idx !== arr.length - 1 && (
                <div className="edu_card_modal_join_professor_tooltip_item_divider" />
              )}
            </li>
          ))}
        </ul>
      }
      place="right-start"
    />
  );
}
