import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import "./HomeSecond.css";

import CardContents from "@/pages/main/renewal/top-content/components/CardContents";
import { getNewestProgram } from "@/api/main/main";
import { getIntroduceListModal } from "@/api/lms";
import EducationCuriDetail from "@/components/Lms/curriculum/modal/EducationCuriDetail";
import Modal from "@/components/common/modals/Modal";

import ThumbNail from "@/assets/svg/main/card_thumbnail.png";
import Poster from "@/assets/svg/main/poster.jpg";
import calender_navy from "@/assets/svg/calender_navy.svg";

import useFormatDate from "@/hooks/useFormatDate";
import { getTokenCookies } from "@/utils/cookies";
import { truncateText } from "@/utils/validation";

const HomeSecond = () => {
  const navigate = useNavigate();
  const { userInfo } = getTokenCookies();
  const isBtnHide = location.pathname === "/education/course";
  const swiperRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [programCardData, setProgramCardData] = useState(null);
  const [currentCardPage, setCurrentCardPage] = useState(1);
  const [cards, setCards] = useState([]);
  const [totalCardPages, setTotalCardPages] = useState(0);

  const itemsPerCardPage = 3;

  const [loading, setLoading] = useState(true);
  const [animationDirection, setAnimationDirection] = useState(""); // 애니메이션 방향

  const fetchNewestProgram = async () => {
    setLoading(true); // 데이터 로드 시작
    try {
      const { data } = await getNewestProgram();
      console.log("data: ", data);
      setProgramCardData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // 데이터 로드 완료
    }
  };

  const fetchModalContent = async () => {
    try {
      const { data } = await getIntroduceListModal({
        projectNo: programCardData.project_no,
        eduNo: programCardData.edu_no,
      });
      console.log(data);
      setModalContent(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenModal = async () => {
    if (loading) {
      console.error("Loading data, please wait.");
      return; // 로딩 중일 때는 함수 종료
    }

    // programCardData가 아직 로드되지 않았을 때
    if (!programCardData) {
      console.error("Program data is not loaded yet.");
      return; // 데이터가 없으면 함수 종료
    }

    const project_no = programCardData?.project_no || null;
    const edu_no = programCardData?.edu_no || null;

    // project_no 또는 edu_no가 없을 때
    if (!project_no || !edu_no) {
      console.error("Project number or education number is missing.");
      return;
    }

    setIsModalOpen(true); // 모달 열기
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const updateCurrentPage = (swiper) => {
    // `realIndex`로 현재 페이지를 계산
    const realIndex = swiper.realIndex;
    setCurrentCardPage(realIndex + 1);
  };

  useEffect(() => {
    fetchNewestProgram(); // 컴포넌트가 마운트될 때 호출
  }, []);

  useEffect(() => {
    if (programCardData && programCardData.length > 0) {
      console.log("데이터 확인: ", programCardData);
      setCards([
        {
          key: "카드1",
          content: (
            <CardContents
              programData={programCardData && programCardData[0]}
              className={"card_box"}
              title={programCardData && programCardData[0]?.edu_name}
              subTitle={programCardData && programCardData[0]?.edu_type}
              description={programCardData && programCardData[0]?.edu_content}
              button={false}
              showModal={true} // 모달을 표시할 카드에서 true 설정
              handleOpenModal={() => handleOpenModal("대학 중심 지역 협업체계 강화")} // 모달 열기
            />
          ),
        },
        {
          key: "카드2",
          content: (
            <CardContents
              className={"card_box"}
              title={truncateText(
                "[로컬창업교육포럼]2024 로컬 기업가정신 및 창업교육 운영 현황 조사_KoEF 이윤석 본부장님",
                60,
              )}
              subTitle={"창업교육 콘텐츠"}
              description={"2024.11.19"}
              handleNavLink={() => navigate("/board/notice/2034/40")}
            />
          ),
        },
        {
          key: "카드3",
          content: (
            <CardContents
              className={"card_box contents_box"}
              title={"2023 산학협력 EXPO"}
              subTitle={"창업교육 콘텐츠"}
              description={"2024.07.15"}
              imgSrc={ThumbNail}
              handleNavLink={() => navigate("/board/eduContents/videoRef/2117/41")}
            />
          ),
        },
        {
          key: "갤러리",
          content: (
            <CardContents
              className={"card_box"}
              title={"2024 창업교육정책세미나"}
              subTitle={"창업교육 콘텐츠"}
              description={"2024.11.19"}
              handleNavLink={() => navigate("/board/eduContents/gallery/2151/322")}
              imgSrc={Poster}
            />
          ),
        },
        {
          key: "공지사항",
          content: (
            <CardContents
              className={"card_box"}
              title={<div>창업교육 혁신 선도대학 SCOUT 통합 플랫폼 사용자 매뉴얼 배포</div>}
              subTitle={"공지사항"}
              description={"2024.05.21"}
              handleNavLink={() => navigate("/board/notice/2036/40")}
            />
          ),
        },
        {
          key: "기타",
          content: (
            <CardContents
              className={"card_box"}
              title={<div>2024 산학연협력 EXPO - 공식 홍보영상</div>}
              subTitle={"기타"}
              description={"2024.10.5"}
              url={"https://www.youtube.com/watch?v=Ynq_aX-dXGM"}
              button={false}
            />
          ),
        },
      ]);

      if (programCardData && programCardData.project_no && programCardData.edu_no) {
        fetchModalContent();
      }
    }
  }, [programCardData]);

  useEffect(() => {
    setTotalCardPages(cards.length);
  }, [cards]);

  useEffect(() => {
    console.log("currentCardPage", currentCardPage);
  }, [currentCardPage]);

  return (
    <div className="home_second_wrapper">
      {loading ? (
        <div>...loading</div>
      ) : (
        <div className="home_second_contents_wrapper">
          <div className="home_second_programs">
            <div className="home_second_programs_top">
              <div className="home_second_programs_title">프로그램</div>
              <div className="home_second_pagination">
                <button className="pagination_arrow" onClick={handlePrev}>
                  &lt;
                </button>
                <span className="pagination_text">
                  {currentCardPage} / {Math.ceil(cards.length)}
                </span>
                <button className="pagination_arrow" onClick={handleNext}>
                  &gt;
                </button>
              </div>
            </div>
            <div className="home_second_programs_cards">
              <Swiper
                modules={[Navigation]}
                loop={true}
                slidesPerView={3}
                spaceBetween={30}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper; // Swiper 인스턴스를 참조에 저장
                }}
                onSlideChange={(swiper) => {
                  updateCurrentPage(swiper);
                }}
              >
                {cards.map((card) => (
                  <SwiperSlide key={card.key}>
                    <div className="home_second_program_card">{card.content}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="home_second_education_contents">
            <div className="home_second_education_contents_wrapper">
              <div className="home_second_education_contents_left">
                <div className="home_second_education_contents_title">창업 교육 컨텐츠</div>
                <div className="home_second_education_contents_description">
                  창업 교육 컨텐츠 설명
                </div>
              </div>
              <div className="home_second_education_contents_right"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeSecond;
