import { createSlice } from "@reduxjs/toolkit";
import { jsonDeepCopy } from "@/utils/common";

const baseInfoSlice = createSlice({
  name: "baseInfoSlice",
  // 이니셜 스테이트
  initialState: {
    eduProjectCreator: null, // 프로젝트 대표 개설자
    eduProjectName: "", // 프로젝트 이름
    eduExp: "", // 프로젝트 설명
    eduFields: "", // 권역 type 뭉치뭉치

    proposeSdate: "", // 프로그램 신청 시작 기간
    proposeEdate: "", // 프로그램 신청 마감 기간

    studySdate: "", // 프로그램 학습 시작 기간
    studyEdate: "", // 프로그램 학습 마감 기간

    selectedAreaButtons: [], // 선택된 권역 배열
    eduProjectJoinerInfoList: [], // 프로그램 공동 개설자 리스트
    completionInstitutionInfoList: [], // 프로그램 공동 개설 발급기관 리스트

    isApplicationRequired: false, // 신청서 여부
    applicationFormList: [], // 신청서 정보 리스트
  },
  reducers: {
    // ! baseInfo 리셋
    resetBaseInfoStates: (state) => {
      state.eduProjectName = "";
      state.eduExp = "";
      state.eduFields = "";
      state.proposeSdate = "";
      state.proposeEdate = "";
      state.studySdate = "";
      state.studyEdate = "";
      state.selectedAreaButtons = [];
      state.eduProjectJoinerInfoList = [];
      state.completionInstitutionInfoList = [];
      state.isApplicationRequired = false;
      state.applicationFormList = [];
    },

    // ! 프로그램 대표 개설자 setState ( 공동 프로그램 개설 시 필요 )
    setEduProjectCreator: (state, { payload }) => {
      state.eduProjectCreator = payload;
    },

    // ! 프로그램 신청 기간 setState
    setProposeSdate: (state, { payload }) => {
      state.proposeSdate = payload;
    },
    // ! 프로그램 마감 기간 setState
    setProposeEdate: (state, { payload }) => {
      state.proposeEdate = payload;
    },
    // ! 프로그램 학습 시작 기간 setState
    setStudySdate: (state, { payload }) => {
      state.studySdate = payload;
    },
    // ! 프로그램 학습 마감 기간 setState
    setStudyEdate: (state, { payload }) => {
      state.studyEdate = payload;
    },

    // ! 프로그램 이름 setState
    setEduProjectName: (state, { payload }) => {
      state.eduProjectName = payload;
    },

    // ! 프로그램 설명 setState
    setEduExp: (state, { payload }) => {
      state.eduExp = payload;
    },

    // ! 권역 선택 setState
    setSelectedAreaButtons: (state, { payload }) => {
      // set state
      state.selectedAreaButtons = payload;

      if (!payload.length) state.eduFields = "";
      // * 권역데이터 병합 처리
      // ? 단일 권역 셀렉트인 경우
      else if (payload.length === 1) state.eduFields = payload[0].type;
      // ? 공동 교수자가 없을 때 일반적으로 처리
      else if (state.eduProjectJoinerInfoList.length === 1) {
        state.eduFields = payload.reduce((acc, cur, idx) => {
          if (idx === 1) return acc.type + "|" + cur.type;
          return acc + "|" + cur.type;
        });
      }
      // ? 공동교수자가 존재하는 경우 중복제거 및
      else if (state.eduProjectJoinerInfoList.length > 1) {
        // 선택된 권역 리스트에 중복되는 대학교이름을 가진 item을 삭제
        const nameArr = state.eduProjectJoinerInfoList.map(
          (professorItem) => professorItem.educatorUniv,
        );
        // 중복제거 후 item이 존재하면 univ name만을 권역에 추가해줌
        const splitFields = [...state.eduFields.split("|"), ...nameArr];
        const setFields = [...new Set(splitFields)];
        state.eduFields = setFields.join("|");
      }
    },

    // ! 공동 프로그램 개설 - 교수자 리스트
    // ? 공동 교육과정 개설 - 교수자 리스트 세팅( 전체 세팅 )
    setEduProjectJoinerInfoList: (state, { payload }) => {
      // 교수자 리스트 세팅
      state.eduProjectJoinerInfoList = payload;

      // * "소속 대학" 권역인 경우 권역데이터 병합 처리
      if (state.selectedAreaButtons.some((item) => item.name === "소속 대학")) {
        // 선택된 권역 리스트에 중복되는 대학교이름을 가진 item을 삭제
        const nameArr = payload.map((professorItem) => professorItem.educatorUniv);
        // 중복제거 후 item이 존재하면 univ name만을 권역에 추가해줌
        const splitFields = [...state.eduFields.split("|"), ...nameArr];
        const setFields = [...new Set(splitFields)];
        state.eduFields = setFields.join("|");
      }
    },

    // ? 미사용중이라 주석처리
    // // ? 공동 교육과정 개설 - 교수자 리스트 추가( 단순 추가 push )
    // addEduProjectJoinerInfoList: (state, { payload }) => {
    //   console.log("교수자 인포임", payload);
    //   state.eduProjectJoinerInfoList = [...state.eduProjectJoinerInfoList, payload];
    // },

    // ? 공동 교육과정 개설 - 교수자 리스트 삭제 ( educatorNo(교수자 pk) 필요 )
    deleteEduProjectJoinerInfoList: (state, { payload }) => {
      // "소속 대학" 권역 포함 여부
      const isUnivField = state.selectedAreaButtons.some((item) => item.name === "소속 대학");

      // 셀렉트된 아이템의 대학교명을 담을 변수
      let targetName = "";

      // 선택된 교수자가 삭제된 배열 생성
      const filteredArr = state.eduProjectJoinerInfoList.filter((item) => {
        // "소속 대학" 권역인 경우 셀렉트된 아이템의 대학교명을 변수에 할당
        if (isUnivField && item.educatorNo === payload) targetName = item.educatorUniv;
        // 타겟 아이템을 필터하여 배열에서 제외
        return item.educatorNo !== payload;
      });

      // 교수자가 삭제된 배열을 store에 저장
      state.eduProjectJoinerInfoList = filteredArr;

      // * "소속 대학" 권역인 경우 권역데이터 삭제 및 병합 처리
      if (isUnivField) {
        // 셀렉트된 대학교명 통해 필터링
        const filteredSplitFields = state.eduFields
          .split("|")
          .filter((fieldName) => fieldName !== targetName);
        // 타겟 item이 삭제된 filtered 배열에서 대학교명만 배열에 담음
        const nameArr = filteredArr.map((professorItem) => professorItem.educatorUniv);
        // 중복제거
        const setFields = [...new Set([...filteredSplitFields, ...nameArr])];
        // 다시 권역 형으로 join
        state.eduFields = setFields.join("|");
      }
    },

    // ! 공동 프로그램 개설 - 발급기관 (수료증)
    // ? 공동 교육과정 개설 - 수료증 발급기관 리스트 세팅( 전체 세팅 )
    setCompletionInstitutionInfoList: (state, { payload }) => {
      state.completionInstitutionInfoList = payload;
    },
    // ? 공동 교육과정 개설 - 수료증 발급기관 리스트 추가( 단순 추가 push )
    addCompletionInstitutionInfoList: (state, { payload }) => {
      state.completionInstitutionInfoList = [...state.completionInstitutionInfoList, payload];
    },
    // ? 공동 교육과정 개설 - 특정 발급기관 이름 변경 setState ( ciInfoNo( pk ), value 필요 )
    setCompletionInstitutionInfoName: (state, { payload }) => {
      const { ciInfoNo, value } = payload;
      state.completionInstitutionInfoList.map((info) => {
        if (ciInfoNo === info.ciInfoNo) info.completionInstitution = value;
        return info;
      });
    },
    // ? 공동 교육과정 개설 - 특정 발급기관 객체 삭제 setState ( ciInfoNo( pk ) 필요 )
    deleteCompletionInstitutionInfoListItem: (state, { payload }) => {
      state.completionInstitutionInfoList = state.completionInstitutionInfoList.filter(
        (item) => item.ciInfoNo !== payload,
      );
    },

    // ! 공동 프로그램 개설 - 발급기관 - 파일 (수료증)
    // ? 공동 교육과정 개설 - 특정 발급기관 관인 파일이름 변경 setState ( ciInfoNo( pk ), value 필요 )
    setCompletionInstitutionInfoStampFileName: (state, { payload }) => {
      const { ciInfoNo, value } = payload;
      state.completionInstitutionInfoList.map((info) => {
        if (ciInfoNo === info.ciInfoNo) info.stampImgFileName = value;
        return info;
      });
    },
    // ? 공동 교육과정 개설 - 특정 발급기관에 등록된 이미지 업로드 여부 변경 ( Y/N state, ciInfoNo( pk ) 필요 )
    setCompletionInstitutionInfoStampFileYn: (state, { payload }) => {
      const { ciInfoNo, YnState } = payload;
      state.completionInstitutionInfoList.map((info) => {
        if (ciInfoNo === info.ciInfoNo) info.stampImgFileExistYN = YnState;
        return info;
      });
    },

    // ! 프로그램 신청서
    // ? 프로그램 신청서 등록 여부
    setIsApplicationRequired: (state, { payload }) => {
      state.isApplicationRequired = payload;
    },
    // ? 프로그램 신청서 리스트 세팅 ( 전체 세팅 )
    setApplicationFormList: (state, { payload }) => {
      state.applicationFormList = payload;
    },
    // ? 프로그램 신청서 리스트 추가 ( 단순 추가 push )
    addApplicationFormList: (state, { payload }) => {
      state.applicationFormList = [...state.applicationFormList, payload];
    },
    // ? 프로그램 신청서 리스트 특정 파일이름 변경 setState ( afNo( pk ), value 필요 )
    setTargetApplicationFormItemFileName: (state, { payload }) => {
      const { afNo, value } = payload;
      state.applicationFormList.map((info) => {
        if (afNo === info.afNo) info.fileName = value;
        return info;
      });
    },
    // ? 프로그램 신청서 리스트 특정 파일 삭제 setState ( afNo( pk ), value 필요 )
    deleteTargetApplicationFormItemFile: (state, { payload }) => {
      state.completionInstitutionInfoList = state.completionInstitutionInfoList.filter(
        (item) => item.afNo !== payload,
      );
    },
  },
});

export const {
  setIsApplicationRequired,
  setApplicationFormList,
  addApplicationFormList,
  setTargetApplicationFormItemFileName,
  deleteTargetApplicationFormItemFile,
  deleteEduProjectJoinerInfoList,
  setEduProjectCreator,
  setProposeSdate,
  setProposeEdate,
  setStudyEdate,
  setStudySdate,
  deleteCompletionInstitutionInfoListItem,
  setCompletionInstitutionInfoStampFileYn,
  setCompletionInstitutionInfoStampFileName,
  setCompletionInstitutionInfoName,
  addCompletionInstitutionInfoList,
  setCompletionInstitutionInfoList,
  setEduProjectJoinerInfoList,
  resetBaseInfoStates,
  setSelectedAreaButtons,
  setEduProjectName,
  setEduExp,
} = baseInfoSlice.actions;

export default baseInfoSlice;
