import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

const HeaderAuth = ({ isMobile = false, isTablet = false, handleLogout, setIsMenuOpen }) => {
  const { userInfo, accessToken } = useSelector((state) => state.authSlice);

  return (
    <>
      {/* 회원 이름 */}
      {accessToken && userInfo?.cName && <p className="userinfo_name">{userInfo?.cName} 님</p>}

      {/* 회원 메뉴 */}
      <div className={[isMobile || isTablet ? "mobile_auth_box" : "auth_box"].join(" ")}>
        <ul className="header_auth_box_wrapper">
          {!(accessToken && userInfo?.cName) ? (
            <>
              <li>
                <Link to="auth/login" onClick={() => setIsMenuOpen()}>
                  로그인
                </Link>
              </li>

              {/* divider */}
              <div className="header_auth_divider" />

              <li>
                <Link to="auth/signup" onClick={() => setIsMenuOpen()}>
                  회원가입
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <a
                  className="cursor"
                  onClick={(e) => {
                    setIsMenuOpen();
                    handleLogout(e);
                  }}
                >
                  로그아웃
                </a>
              </li>

              {/* divider */}
              <div className="header_auth_divider" />

              <li>
                <Link to="auth/mypage" onClick={() => setIsMenuOpen()}>
                  마이페이지
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default HeaderAuth;
