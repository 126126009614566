import React from "react";
import ReactDOM from "react-dom/client"; // 여기를 수정
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
// 리액트 쿼리 설정
import { QueryClient, QueryClientProvider } from "react-query";

import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-image-crop/dist/ReactCrop.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "@/styles/reset.css";
import "@/styles/Onest-font.css";
import "@/styles/Pretendard-font.css";
import "@/styles/common.css";
import "@/styles/pagenation.css";
import "@/styles/normalize.css";
// 컴포넌트 공통 스타일
import "@/styles/common-component-style.js";
// import reportWebVitals from "./reportWebVitals";

import "@/pages/about/ConsortiumAbout/route/UniversityHome.css";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>,
);

// 웹 바이탈 확인
// google Analytics를 연결해서 시각화해서 확인할 수 있습니다
// reportWebVitals(console.log);
