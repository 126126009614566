import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as AlarmIcon } from "@/assets/svg/icon_alarm.svg";
import { getPlatformAlarmList, setAlarmIsRead, deleteAlarm } from "@/api/alarm";
import AlarmModal from "./sub/alarmModal/AlarmModal";
import "./AlarmListener.css";

const AlarmListener = ({ cNo, token }) => {
  const [alarms, setAlarms] = useState([]);
  const [hasNewAlarm, setHasNewAlarm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // 알림 모달 열기
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setHasNewAlarm(false); // 새 알림 상태 해제
  };

  // 알림 모달 닫기
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteAlarm = async (alarmNo) => {
    const isConfirmed = window.confirm("알림을 삭제하시겠습니까?");
    if (!isConfirmed) return;
    try {
      const body = {
        alarmNo: alarmNo,
        memberNo: cNo,
      };
      await deleteAlarm(body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAlarms((prev) => prev.filter((alarm) => alarm.alarmNo !== alarmNo));
      toast.success("알림을 삭제했습니다.");
    } catch (error) {
      console.error("알림 삭제 중 오류 발생:", error);
      toast.error("알림을 삭제하는 중 오류가 발생했습니다.");
    }
  };

  const handleMarkAsRead = async (alarmNo) => {
    try {
      const body = {
        alarmNo: alarmNo,
        memberNo: cNo,
      };
      await setAlarmIsRead(body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAlarms((prev) =>
        prev.map((alarm) => (alarm.alarmNo === alarmNo ? { ...alarm, isRead: true } : alarm)),
      );
      toast.success("알림을 읽음 처리했습니다.");
    } catch (error) {
      console.error("알림 읽음 처리 중 오류 발생:", error);
      toast.error("알림을 읽음 처리하는 중 오류가 발생했습니다.");
    }
  };

  // 새 메시지를 받을 경우 Toast로 표시
  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_URL_JAVA}api/alarm/connect/noneauth?userId=${cNo}`,
    );

    // 서버에서 메시지를 받을 때 실행
    eventSource.onmessage = (event) => {
      console.log("event:", event);
      const alarmData = event.data;
      try {
        console.log("작동함?");
        toast.info(`📩 New message: ${alarmData}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setHasNewAlarm(true);
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    };

    // 연결 오류 시 처리
    eventSource.onerror = (error) => {
      console.error("EventSource connection error!", error);
    };

    // 컴포넌트 언마운트 시 연결 종료
    return () => {
      eventSource.close();
    };
  }, []);

  // alarm 데이터 가져오기
  useEffect(() => {
    const fetchAlarms = async () => {
      try {
        setLoading(true);
        const response = await getPlatformAlarmList(cNo, headers);
        setAlarms(response.data.alarmData);
        setHasNewAlarm(response.data.alarmData.some((alarm) => !alarm.isRead));
      } catch (error) {
        console.error("알림 데이터를 불러오는 중 오류 발생:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAlarms();
  }, [cNo, token]);

  return (
    <div className="alarm_listener">
      <div className="alarm_icon_wrapper" onClick={handleOpenModal}>
        <AlarmIcon className="alarm_icon" />
        {hasNewAlarm && <span className="alarm_dot"></span>} {/* 새 알림 붉은 점 */}
      </div>

      {/* 알림 모달 */}
      <AlarmModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        memberNo={cNo}
        token={token}
        alarms={alarms}
        onDelete={handleDeleteAlarm}
        onMarkAsRead={handleMarkAsRead}
      />
    </div>
  );
};

export default AlarmListener;
