//javascript validate

import {format} from "date-fns";

/**
 * @description validate of regExp
 * @param {any} targetTxt
 * @param {RegExp} regExp
 * @returns boolean
 */
export function validateRegExp(targetTxt, regExp) {
  return regExp.test(String(targetTxt));
}

/**
 * @description 문자를 byte 폼으로 계산하여 한글 3~20자, 영문 5~40자 까지 검증하여 true || false 를 반환
 * @param { string } text
 * @param { number } maxByte
 * @returns {{ byte: number, isValidate: boolean }}
 */
export function validateByteFormLength(text, maxByte = 40) {
  let byte = 0;
  let isValidate = true;

  // 글자를 순회하며 byte 단위로 검사
  for (let k = 0; k < text.length; k++) {
    // 순회중 현재 글자의 char code
    const char = text.charCodeAt(k);

    // char code로 한글 식별
    if (char >= 0xac00 && char <= 0xd7af) byte += 2;
    else byte++;

    // maxByte를 넘기면 반복문을 빠져나옴
    if (byte > maxByte) {
      isValidate = false;
      break;
    }
  }

  // 4byte 이하일 때 false 반환
  if (byte < 5) isValidate = false;

  return {isValidate, byte};
}

/**
 * @description 파일 사이즈 MB로 소수점 1의 자리까지 반환
 * @param { number } size
 * @returns { number }
 */
export const getMB = (size) => Number((size / 1024 / 1024).toFixed(1));

/**
 * @description 1번째 매개변수에 넣은 글자를 byte단위로 체크하여 ...처리 해줍니다.
 * @param { string } text
 * @param { number } maxByte
 * @returns { string }
 */
export function truncateText(text = "", maxByte = 20) {
  if (typeof text !== "string") return "";

  let byte = 0;
  let txt = "";

  // 글자를 순회하며 byte단위로 체크 후
  for (let k = 0; k < text.length; k++) {
    // 순회중 현재 글자의 char code
    const char = text.charCodeAt(k);

    // char code로 한글 식별 후 byte 합산
    if (char >= 0xac00 && char <= 0xd7af) byte += 2;
    else byte++;

    // 문자열 반복 추가
    txt += text[k];

    // maxByte 넘기기 직전 말줌임표 추가 후 종료
    if (byte > maxByte - 2) return (txt += "...");
  }
  return txt;
}

// New Mark true/false 함수 (호이스팅을 위해 function 선언)
export function newMarkSelector(targetDate) {
  const setDate = new Date(targetDate);
  const today = format(Date.now(), "yyyyMMdd");
  const convertDate = setDate.setDate(setDate.getDate() + 5); // 현재 기준일 5일
  const newMarkLimit = format(convertDate, "yyyyMMdd");
  return today < newMarkLimit;
}

/**
 * @description 해당 기간인지 검사 - 신청기간 or 학습기간 등
 * @param { string || undefined  } startDate 시작 날짜
 * @param { string || undefined  } endDate 끝 날짜
 * @param { function } callBackFunc? 변경할 함수 - ex) useState에서 set함수
 * @returns { boolean | void } 기간이 유효한지 여부 (callBackFunc가 없을 때는 boolean 반환)
 * @example
 * 1.validateEduDate(startDate, undefined, callBackFunc);
 * 2.validateEduDate(undefined, endDate, callBackFunc);
 * 3.let isVaildDate = validateEduDate(startDate, endDate);
 */
export function validateEduDate(startDate, endDate, callBackFunc) {
  const nowDate = new Date();

  const s_date = startDate ? new Date(startDate) : undefined;
  const e_date = endDate ? new Date(endDate) : undefined;

  if (e_date) {
    e_date.setHours(23, 59, 59, 999); // 끝 기간의 시간을 23시 59분으로 설정
  }

  let isValidPeriod = false;

  // 시작 날짜만 있는 경우
  if (s_date && !e_date) {
    isValidPeriod = nowDate >= s_date;
    // 끝 날짜만 있는 경우
  } else if (!s_date && e_date) {
    isValidPeriod = nowDate <= e_date;
    // 시작 날짜와 끝 날짜가 모두 있는 경우
  } else if (s_date && e_date) {
    isValidPeriod = nowDate >= s_date && nowDate <= e_date;
  }

  // callBackFunc이 없을 경우 값을 반환
  if (callBackFunc) {
    callBackFunc(isValidPeriod);
  } else {
    return isValidPeriod;
  }
}
