import "./EducationCardModal.css"; //해당 모달 컴포넌트 css
import React, { useEffect, useState } from "react";
import calender_navy from "@/assets/svg/calender_navy.svg";
import useFormatDate from "@/hooks/useFormatDate";
import { getIntroduceListModal } from "@/api/lms";
import EducationCuriDetail from "@/components/Lms/curriculum/modal/EducationCuriDetail";

import { useLocation, useNavigate } from "react-router-dom";
import { getTokenCookies } from "@/utils/cookies";

import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import EduProjectJoinProfessorTooltip from "@/components/common/tooltip/EduProjectJoinProfessorTooltip";

// * 프로그램 소개, 프로그램 신청하기 페이지 ( 진행중인 프로그램 ) - 상세모달
export default function EducationCardModal({ setShowModal, modalData }) {
  // 쿠키에서 가져오는 token, userInfo 정보
  const { accessToken, userInfo } = getTokenCookies();
  const navigate = useNavigate();
  const { cNo, cGubun } = userInfo;
  const [curiData, setCuriData] = useState(null);

  // 신청하기 버튼 ( 학생 + 소개페이지)
  const location = useLocation();
  const isBtnHide = location.pathname === "/education/course";

  const handleClose = () => {
    setShowModal(false);
  };

  const handleApplyList = () => {
    if (new Date(curiData?.modalBaseData?.proposeEdate).getTime() < new Date().getTime()) return;

    if (!cNo && confirm("로그인이 필요한 페이지입니다. 로그인 페이지로 이동합니다.")) {
      navigate("/auth/login");
    }
    if (cNo && confirm("신청을 위해 프로그램 신청 페이지로 이동합니다.")) {
      navigate("/education/list");
    }
  };

  useEffect(() => {
    console.log("modalData", modalData);
  }, [modalData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!modalData?.projectNo_using) {
        console.log("프로젝트 넘버없음", modalData);
        toast.error("상세 데이터를 불러오지 못했습니다.");
        return;
      }
      if (!modalData?.eduNo) {
        console.log("에듀 넘버없음", modalData);
        toast.error("상세 데이터를 불러오지 못했습니다.");
        return;
      }
      try {
        const requestData = {
          projectNo: modalData?.projectNo_using,
          eduNo: modalData.eduNo,
        };
        const res = await getIntroduceListModal(requestData);
        setCuriData(res.data);
        console.log("res.data: ", res.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [modalData]);

  return (
    <>
      <div className="eduModal_container">
        <span>{curiData?.modalBaseData?.eduType}</span>
        <h3>{curiData?.modalBaseData?.eduName}</h3>

        <p className="subTitle">
          {/* 권역, 대학교 */}
          <span>
            {`${curiData?.modalBaseData?.educatorFieldName} ${curiData?.modalBaseData?.educatorUnivName}`}
          </span>

          {/* 교수자 이름 */}
          <span className="edu_card_modal_professor_name">
            {curiData?.modalBaseData?.educatorName} 교수
          </span>

          {/* 공동 교수자 카운트 ( 교수자 툴팁 ) */}
          {!!curiData?.joinEducatorInfo?.cnt && (
            <span data-tooltip-id="edu_project_join_professor">
              {"외 "}
              <span className="edu_card_modal_join_professor_cnt">
                {curiData?.joinEducatorInfo?.cnt}명
              </span>
            </span>
          )}
        </p>

        {/* 일단 빼는걸로 수정 - 0418 하연 */}
        {/* <div className="briefDescription">
            <p className="curriculum_description">{curiData?.modalBaseData.eduExp}</p>
          </div> */}
      </div>

      <div className="eduModal_contents">
        <div className="studentInfo">
          {/* 인원수 div 없애면서 인라인 스타일로 마진 값 없앰 */}
          <div className="studyPeriod" style={{ marginLeft: "0px" }}>
            <img src={calender_navy} alt="calender_navy" />
            <p>신청기간</p>
            <span>{`${useFormatDate(
              curiData?.modalBaseData.proposeSdate,
              "yyyy.MM.dd",
            )} ~ ${useFormatDate(curiData?.modalBaseData?.proposeEdate, "yyyy.MM.dd")}`}</span>
          </div>
          <div className="studyPeriod" style={{ marginLeft: "0px" }}>
            <img src={calender_navy} alt="calender_navy" />
            <p>학습기간</p>
            <span>{`${useFormatDate(
              curiData?.modalBaseData.studySdate,
              "yyyy.MM.dd",
            )} ~ ${useFormatDate(curiData?.modalBaseData?.studyEdate, "yyyy.MM.dd")}`}</span>
          </div>

          {/* 교수자일 경우에만 신청하기 버튼 삭제 */}
          {/* proposeEdate가 넘었을 때 신청하기 버튼 disabled */}
          {/* new Date(curiData.modalBaseData.proposeEdate가).getTime() < new Date().getTime() */}
          {isBtnHide && cGubun !== 1 && (
            <button
              className={
                new Date(curiData?.modalBaseData?.proposeEdate).getTime() < new Date().getTime()
                  ? "modal_apply_btn"
                  : "modal_disabled_btn"
              }
              onClick={handleApplyList}
            >
              신청하기
            </button>
          )}
        </div>
      </div>

      {/* Divider */}
      <div className="edu_card_modal_divider" />

      {/* 수료기준 - 고정값*/}
      <div className="CompletionCriteria">
        <dl className="description_box">
          <dt>수료기준</dt>
          <dd>
            <ul>
              <li>
                <span>온라인 교육 :</span> 학습 기간 내에 교육 영상의 80% 이상 시청해야 출석으로
                인정됩니다.
              </li>
              <li>
                <span>오프라인 교육 :</span> 공지 확인 후 오프라인 교육에 참여해야 출석으로
                인정됩니다.
              </li>
              <li>
                <span>온오프 혼합교육 :</span>
                공지 확인 후 오프라인 교육에 참여해야 출석으로 인정됩니다.
              </li>
              <li>
                <span>실시간 화상 교육 :</span> 해당 실시간 화상 교육에 참여해야 출석으로
                인정됩니다.
              </li>
              <li>
                <span>* 수료 기준은 프로그램마다 상이할 수 있습니다.</span>
              </li>
            </ul>
          </dd>
        </dl>
        <dl className="description_box">
          <dt>수료증</dt>
          <dd>
            <ul>
              <li>모든 강좌의 수료기준 충족 시 수료증 출력이 가능합니다.</li>
            </ul>
          </dd>
        </dl>
      </div>

      {/* 프로그램 내용 */}
      <EducationCuriDetail curiData={curiData} />

      {/* 교수자 명단 툴팁 */}
      {!!curiData?.joinEducatorInfo?.list?.length && (
        <EduProjectJoinProfessorTooltip listData={curiData?.joinEducatorInfo?.list} />
      )}
    </>
  );
}
