import { LmsJavaApi, LmsNodeApi } from "./index";

/**
 * @description lms 교육참여 목록 조회
 * @param {number} memberNo - 로그인 한 유저의 member no
 */
export function getEduParticipateList(memberNo) {
  return LmsNodeApi.get("/education/list", { params: memberNo });
}

/**
 * @description lms 교육 참여 교육 수강 (학생화면) - 과제제출 및 변경
 */

export function postAssignment(formData) {
  return LmsJavaApi.post("/submitassignment", formData);
}

/**
 * @description lms 교육 참여 교육 수강 (학생, 교수자 화면)
 * @param {object} lectureData
 */

export function getLectureInfo(
  c_home,
  c_edu_no,
  c_edu_project_no,
  c_member_no,
  c_project_no,
  c_team_no,
  c_curi_no,
) {
  // 여기서는 동적으로 받은 값을 사용합니다.
  return LmsNodeApi.get(
    `/edu/getedugeneralinfo?home=${c_home}&eduNo=${c_edu_no}&eduProjectNo=${c_edu_project_no}&memberNo=${c_member_no}&projectNo=${c_project_no}&teamNo=${c_team_no}&curiNo=${c_curi_no}`,
  );
}

/**
 * @description lms 이수처리 목록 리스트
 */
export function getParticipantList(projectNo, eduNo, order) {
  return LmsJavaApi.get("/getparticipantlist", { params: { projectNo, eduNo, order } });
}

/**
 * @description lms 이수처리 업데이트 (승인)
 */
export function updateUserCompletion(data) {
  return LmsJavaApi.post("/updateusercompletion", data);
}

/**
 * @description lms 이수처리 업데이트 (취소)
 */
export function updateUserIncomplete(data) {
  return LmsJavaApi.post("/updateuserincomplete", data);
}

/**
 * @description lms 교육 이수 차시 리스트 목록
 */
export function getChasiListforCompletion(eduNo) {
  return LmsNodeApi.get("/edu/getchasilistforcompletion", { params: { eduNo } });
}

/**
 * @description lms 신청 학생 리스트
 */
export function getApplyStudentList(projectNo) {
  return LmsJavaApi.get("/getapplystudentlist", { params: { projectNo } });
}

/**
 * @description lms 학생 승인, 취소 업데이트
 */
export function updateSetApproveStudent(data) {
  return LmsJavaApi.post("/setapprovestudent", data);
}

/**
 * @description lms 내가 개설한 프로그램 (프로그램 관리 페이지)
 */
export function getEduManagementPageInfo(requestData) {
  // const { educatorNo, eduType, findStr, studySdate, studyEdate } = requestData;
  return LmsJavaApi.get("/getedumanagementpageinfo", {
    params: requestData,
  });
}

/**
 * @description 수료증 인쇄 정보 불러오기
 * @param {number} memberNo - 로그인된 유저 id
 * @param {number} projectNo - 해당 프로젝트 id
 * @param {number} eduNo - 해당 교육 프로젝트 id
 */
export function getCertificateInfo(requestData) {
  return LmsJavaApi.post("getcompletioncertificateinfo", requestData);
}

/**
 * @description 만족도 조사
 * @param {number} memberNo - 로그인된 유저 id
 * @param {number} projectNo - 해당 프로젝트 id
 */
export function postSetsurveyok(requestData) {
  return LmsJavaApi.post("setsurveyok", requestData);
}

/**
 * @description lms 교육 영상 비디오 시청 시간 (인터벌로 시간 찍기)
 */
export function setTimeToVideoEdu(data) {
  return LmsJavaApi.post("settimetovideoedu", data);
}

/**
 * @description lms 1분 이하 교육 영상 재생시 즉시 이수 처리
 */
export function setTimeToShortVideoEdu(data) {
  return LmsJavaApi.post("settimetoshortvideoedu", data);
}

/**
 * @description lms 프로그램 소개 - 메인 (카드섹션)
 */
export function getIntroduceList(eduField, findStr) {
  const params = {};

  if (eduField) params.eduField = eduField;
  if (findStr) params.findStr = findStr;

  return LmsNodeApi.get("/edu/getintroducelist", { params });
}

/**
 * @description lms 교육 과정 소개 - 상세일정(모달)
 */
export function getIntroduceListModal(requestData) {
  const { projectNo, eduNo } = requestData;

  return LmsNodeApi.get("/edu/getintroducelistmodal", { params: { projectNo, eduNo } });
}

/**
 * @description 대학별 소개 (교육리스트)
 */
export function getEduListByUniv(requestData) {
  const { univNo, findStr, eduType } = requestData;
  return LmsNodeApi.get("/edu/getedulistbyuniv", { params: { univNo, findStr, eduType } });
}

/**
 * @description startLink 획득
 */
export function postWebexLink(data) {
  return LmsJavaApi.post("/getWebexStartLink", data);
}

/**
 * @description 상세페이지 조회
 * @param {number} memberNo 로그인한 유저 id
 * @param {number} eduNo
 * @param {number} eduProjectNo
 * @param {number} projectNo
 */
export function getEduDetailPage(requestData) {
  return LmsNodeApi.get("education/view", { params: { ...requestData } });
}

/**
 * @description 학생 프로그램 신청 후 취소하기
 */

export function cancelApplyData(data) {
  return LmsJavaApi.post("/cancelapplydata", data);
}

/**
 * @description 추가승인 (학생 검색)
 */
export function findStudent(data) {
  return LmsJavaApi.post("/findstudent", data);
}

/**
 * @description 교육시작 후 학생 수동 승인
 */
export function SetApproveStudent(data) {
  return LmsJavaApi.post("/setapprovestudentmanually", data);
}

/**
 * @description 교육개별 수정 (오프라인 교육 일정 수정) seteducationindividualitem
 */
export function setEducationIndividualItem(data) {
  return LmsJavaApi.post("/seteducationindividualitem", data);
}

/**
 * @description 엑셀 다운로드
 * @param {Arrat} eduProjectNo[] 다운로드 할 프로젝트 no 배열
 */
export function downloadExcelFile(requestData) {
  return LmsJavaApi.post("/download/excel/programmanagement", requestData, {
    responseType: "blob",
  });
}
