import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import NoticeIcon from "@/assets/svg/main/icon_notice.svg";
import PlayingIcon from "@/assets/svg/main/icon_playing.svg";
import PausedIcon from "@/assets/svg/main/icon_paused.svg";
import "./MainNotice.css";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import { useNavigate } from "react-router-dom";
import { getBoardNoticeList } from "@/api/main/main";
import { motion } from "framer-motion";
import useHoverAnimation from "@/hooks/useHoverAnimation";

export default function MainNotice() {
  const navigate = useNavigate();
  const animation = useHoverAnimation();
  const [swiper, setSwiper] = useState(null); // Swiper 인스턴스 저장
  const [isPlaying, setIsPlaying] = useState(true); // 오토플레이 상태 저장
  const [slideIndex, setSlideIndex] = useState(0); // 현재 슬라이드 인덱스
  const [noticeList, setNoticeList] = useState([]);

  const toggleAutoplay = () => {
    if (swiper) {
      if (isPlaying) {
        swiper.autoplay.stop(); // 오토플레이 정지
      } else {
        swiper.autoplay.start(); // 오토플레이 시작
      }
      setIsPlaying(!isPlaying); // 상태 반전
    }
  };

  const noticeList1 = [
    { id: 1, title: "공지사항1", content: "내용1", date: "2024.05.07" },
    { id: 2, title: "공지사항2", content: "내용2", date: "2024.05.07" },
    { id: 3, title: "공지사항3", content: "내용3", date: "2024.05.07" },
    { id: 4, title: "공지사항4", content: "내용4", date: "2024.05.07" },
    { id: 5, title: "공지사항5", content: "내용5", date: "2024.05.07" },
  ];

  const fetchNoticeList = async () => {
    try {
      const { data } = await getBoardNoticeList();
      setNoticeList(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNoticeList();
  }, []);

  return (
    <div className={"main_notice_wrapper"}>
      <div className={"notice_top_box"}>
        <p className={"title"}>
          <img src={NoticeIcon} alt={""} />
          <span>공지사항</span>
        </p>

        <button className="nav_btn" onClick={() => navigate("/board/notice?p=1")}>
          <span>바로가기</span>
          <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
        </button>
      </div>

      <div>
        <Swiper
          breakpoints={{
            1280: {
              slidesPerView: 2,
            },
            1279: {
              slidesPerView: 1,
            },
          }}
          modules={[Navigation, Autoplay]}
          spaceBetween={24}
          // slidesPerView={2}
          speed={1500}
          loop={true}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          loopAdditionalSlides={1}
          onSwiper={(swiperInstance) => setSwiper(swiperInstance)} // Swiper 인스턴스 저장
          onSlideChange={(swiperInstance) => setSlideIndex(swiperInstance.realIndex)} // 슬라이드 변경 이벤트
        >
          {noticeList.map((list) => (
            <SwiperSlide
              key={list.id}
              onClick={() => navigate(`/board/notice/${list.c_no}/${list.c_board_no}`)}
            >
              <motion.div className={"notice_card"} {...animation}>
                <div className={"notice_sub_title"}>
                  <div className={"badge"}>공지사항</div>
                  <div className={"date"}>{list.c_date}</div>
                </div>
                <div className={"notice_title"}>{list.c_title}</div>
                <div
                  className={"content"}
                  dangerouslySetInnerHTML={{ __html: list.c_content }}
                ></div>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* 페이지네이션 */}
      <div className={"notice_pagination"}>
        <div className={"swiper_page"}>
          <span className={"current"}>{slideIndex + 1}</span>
          <span>/</span>
          <span className={"page_length"}>{noticeList.length}</span>
        </div>
        <div className={"play_status"}>
          <button onClick={toggleAutoplay}>
            <img src={isPlaying ? PlayingIcon : PausedIcon} alt="Toggle Autoplay" />
          </button>
        </div>
      </div>
    </div>
  );
}
