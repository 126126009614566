import React from "react";
import { motion } from "framer-motion";

import ProgramImage from "@/assets/images/main/main_program.png";
import RectangleIcon from "@/assets/svg/main/rectangle.svg";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@/assets/svg/main/Chat.svg";
import useHoverAnimation from "@/hooks/useHoverAnimation";

const programContentsList = {
  id: 1,
  content:
    "학생들이 교수님과 함께 창업 아이디어를 발전시키고, 아이디어의 가능성을 분석하는 과정입니다. 교수님은 창업 아이템의 시장성, 기술적 가능성 등을 평가하고, 학생에게 실질적인 조언을 제공합니다.",
};

function ProgramContent({ data }) {
  const navigate = useNavigate();
  const animation = useHoverAnimation();

  return (
    <div className={"tab_content_wrapper"}>
      <div className={"nav_btn_box"}>
        <button className="nav_btn" onClick={() => navigate("/education/course")}>
          <span>바로가기</span>
          <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
        </button>
      </div>

      <div className={"main_content_box"}>
        <motion.div className={"content_img_box"} {...animation}>
          <img src={ProgramImage} alt={""} />
        </motion.div>

        <div className="consortium_content_box">
          <motion.div>
            <p>
              <img src={RectangleIcon} alt={""} />
              <span>주요 성과수치</span>
            </p>

            <ul>
              {data.data.map((content, idx) => (
                <motion.li key={idx} {...animation}>
                  <span>-</span>
                  <span>{content}</span>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <div className={"feature_content_box"}>
            <p>
              <img src={RectangleIcon} alt={""} />
              <span>관련 기능</span>
            </p>

            <motion.div className={"feature_btn_group"} {...animation}>
              <button onClick={() => navigate("/board/mentoring?p=1&myP=1")}>
                <span>멘토링</span>
                <span className="nav_btn">
                  <span>바로가기</span>
                  <img src={RightButton} alt="" />
                </span>
              </button>
            </motion.div>
          </div>
        </div>
      </div>

      <motion.div className={"tab_text_box"} {...animation}>
        <img src={ChatIcon} />

        <div>
          <p style={{ marginBottom: "16px" }}>
            창업에 관심이 있는 분들이라면 누구나 무료로 들을 수 있는 창업교육 프로그램입니다.
          </p>
          <p>
            다양한 주제로 구성되어 있으며, 오프라인수업부터 온라인 실시간 강의 등 편리한 강의 유형을
            제공합니다. 또한, 실제 사용 중인 창업교육 툴킷을 프로그램에 연동할 수 있으며, 프로그램
            내에서 간편히 과제 확인 및 제출까지 가능합니다.
          </p>
        </div>
      </motion.div>

      {/*<SwiperContents list={programContentsList} />*/}
    </div>
  );
}

export default ProgramContent;
